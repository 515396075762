import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { VersioneContenuto } from '../../modello/versione-contenuto'
import { SessionDataService } from '../../servizi/session-data.service'
import { ThemeService } from '../../servizi/theme.service'
import { SessionData } from '../../sessione/dati-sessione'
import { createDocumentFromFile, MAX_FILE_SIZE_MB, MAX_TOTAL_FILE_SIZE_MB, randomString } from '../../utility/utility'

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  fileToUpload: Array<File> = null

  @Input() accept: string
  @Input() multi: boolean
  @Input() label: string
  @Output() onDocumentAdded = new EventEmitter<VersioneContenuto>()
  fileUploadId: string
  totalFileSize: any

  constructor(private snackBar: MatSnackBar, private sessionData: SessionData) { }

  ngOnInit(): void {
    this.fileUploadId = randomString(8,0,0,0)
    this.totalFileSize = 0
  }

  onFilesAdded(files: FileList) {
    let newTotalSize = this.totalFileSize;
    for (const file of files) {
      newTotalSize += file.size / (1024 * 1024); // Converti in MB
    }

    if (newTotalSize > MAX_TOTAL_FILE_SIZE_MB) {
      this.snackBar.open(
        $localize`La dimensione totale dei file supera il limite di ${MAX_TOTAL_FILE_SIZE_MB} MB`,
        null,
        { duration: 4000 }
      );
      return;
    }
    this.fileToUpload = Array.from(files)
    this.fileToUpload.forEach(file => {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        this.fileToUpload.splice(this.fileToUpload.indexOf(file))
        this.snackBar.open($localize `Il file ${file.name} supera il limite dei ${MAX_FILE_SIZE_MB} MB e non può essere allegato`, null, {
          duration: 4000,
        })
      }
      if (file.size === 0) {
        this.fileToUpload.splice(this.fileToUpload.indexOf(file))
        this.snackBar.open($localize `Il file ' + file.name + ' è vuoto e verrà rimosso!!!`, null, {
          duration: 4000,
        })
      }
    })
    if (this.fileToUpload.length===1 && !this.multi) {
      this.onSubmit()
    }
  }

  onSubmit() {
    Promise.all(this.fileToUpload.map(file => createDocumentFromFile(file)))
      .then((documenti: VersioneContenuto[]) => {
        for (const documento  of documenti) {
          this.onDocumentAdded.emit(documento)
        }
      })
  }


  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight)
  }

  eliminaAllegato(allegato) {
    this.fileToUpload = this.fileToUpload.filter(file => file.name !== allegato.name)
    this.totalFileSize -= allegato.dimensione / (1024 * 1024);
  }

}
