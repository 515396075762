<div class="visualizzatore-container">
  <div mat-dialog-title class="dialog-visualizzatore text-center">{{titolo}}</div>
  <mat-dialog-content class="content-container">
    <div class="pdf-container" *ngIf="!audioVideo">
      <ngx-extended-pdf-viewer
        [base64Src]="base64Raw"
        [language]="language"
        [showDownloadButton]="canDownload"
        [showPrintButton]="canPrint"
        [mobileFriendlyZoom]="true"
        [showFindButton]="false"
        [showBorders]="false"
        [showEditor]="false"
        [showOpenFileButton]="false"
        [zoom]="'page-width'"
        [showRotateButton]="rotateButtons"
        [showSidebarButton]="false"
        [showZoomButtons]="zoomButtons"
        [scrollMode]="0"
        [showPagingButtons]="true"
        [showPresentationModeButton]="false"
        [showPropertiesButton]="false"
      ></ngx-extended-pdf-viewer>
    </div>
    <!-- se il documento è un audio o un video -->
    <div *ngIf="audioVideo" class="media-container">
      <app-video-player [videoKey]="videoKey" [tipo]="tipoMedia" [mediaFile]="mediaBlob"></app-video-player>
    </div>
  </mat-dialog-content>
  <div class="action-buttons">
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n *ngIf="!restituisceConsenso" mat-button [mat-dialog-close]="true">Chiudi</button>
    <button i18n *ngIf="restituisceConsenso" mat-button [mat-dialog-close]="true">Ho preso visione dell'informativa privacy</button>
  </div>
</div>
