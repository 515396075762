import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import {
  Documento,
  TipoDocumento,
} from '../../modello/documento'
import { AuthService } from '../../servizi/auth.service'
import { ContenutiService } from '../../servizi/contenuti.service'
import { DocumentiService } from '../../servizi/documenti.service'
import { ESITO_OK } from '../../utility/esito'

@Component({
  selector: 'app-visualizzatore-pdf',
  templateUrl: './visualizzatore-pdf.component.html',
  styleUrls: ['./visualizzatore-pdf.component.scss'],
})
export class VisualizzatorePdfDialogComponent {
  editorConfigTb: AngularEditorConfig = {
    enableToolbar: false,
    showToolbar: false,
    toolbarHiddenButtons: [[], ['insertImage', 'insertVideo']],
    editable: false,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize`Inserisci testo qui...`,
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }
  testo: string
  titolo: string
  documento: Documento
  tipoDocumento: any
  restituisceConsenso: boolean
  private _language = 'it'
  bypassAuth: any
  hidePdfViewer: boolean
  enableDownload: boolean
  enablePrint: boolean
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private snackBar: MatSnackBar,
    private documentiService: DocumentiService,
    private contenutiService: ContenutiService,
    private authService: AuthService
  ) {
    this.testo = data.testo
    this.documento = data.src
    this.titolo = data.titolo
    this.tipoDocumento = data.tipoDocumento
    this.bypassAuth = data.bypassAuth
    this.restituisceConsenso = data.restituisceConsenso
    this.enableDownload = data.canDownload
    this.enablePrint = data.canPrint
    this.language =  this.authService.getLinguaUtente()
    if (data.tipo && data.sottotipo && data.lingua) {
      this.contenutiService
        .getVersioneContenuto(data.tipo, data.sottotipo, data.lingua)
        .subscribe((esito) => {
          // verifica se l'esito è ok, ricava la versione contenuto e se ha un hash lo usa per recuperare il file
          if (esito.esito === ESITO_OK) {
            this.documento = JSON.parse(esito.payload)
            if (this.documento && this.documento['url']) {
              this.contenutiService
                .downloadFile(this.documento['url'])
                .subscribe((fileBlob) => {
                  // Create a new File instance
                  const file = new File([fileBlob], this.documento['url'], {
                    type: fileBlob.type,
                  })

                  // valorizza tipo con il tipo del file
                  this.documento.tipo = file.type

                  // Converting Blob to Base64
                  const reader = new FileReader()
                  reader.readAsDataURL(file)
                  reader.onloadend = () => {
                    const base64data = reader.result
                    this.documento['base64'] = base64data as string
                    this.documento.size = file.size
                  }
                })
            }
          }
        })
    } else if (this.documento) {
      this.caricaDocumento()
    }
  }

  get canDownload(): boolean {
    return this.tipoDocumento === TipoDocumento.TIPO_INFORMATIVA && this.enableDownload
  }

  get canPrint(): boolean {
    return this.tipoDocumento === TipoDocumento.TIPO_INFORMATIVA && this.enablePrint
  }


  get zoomButtons(): boolean {
    // verifica se il display ha una risoluzione minore di 800px
    return window.innerWidth > 600
  }

  get rotateButtons(): boolean {
    // verifica se il display ha una risoluzione minore di 800px
    return window.innerWidth > 600
  }

  disableRightClick(event: MouseEvent) {
    event.preventDefault()
  }

  caricaDocumento() {
    if (this.documento.id === 'OMISSIS') {
      this.snackBar.open(
        $localize`Non sei abilitato al download del documento!`,
        null,
        {
          duration: 6000,
        }
      )
      return
    }
    if (this.documento.base64) {
      // elimina l'intestazione "data:application/pdf;base64," se esiste
      this.documento.base64 =
        this.documento.base64?.indexOf(',') !== -1
          ? this.documento.base64?.split(',')[1]
          : this.documento.base64
      return
    }
    if (!this.documento.hash) {
      this.snackBar.open(
        $localize`Il documento non ha un indirizzo valido!`,
        null,
        {
          duration: 6000,
        }
      )
      return
    }

    this.documentiService
      .downloadDocumento(this.documento, this.tipoDocumento, this.bypassAuth)
      .then((base64) => {
        // elimina l'intestazione "data:application/pdf;base64," se esiste
        this.documento.base64 =
          base64?.indexOf(',') !== -1 ? base64?.split(',')[1] : base64
        /*         if (base64) {
            this.documento.arrayBuffer = _base64ToArrayBuffer(base64?.indexOf(',') !== -1 ? base64?.split(',')[1] : base64);
          }
          if (this.documento.arrayBuffer && !this.documento.base64) {
            this.documento.base64 = _arrayBufferToBase64(this.documento.arrayBuffer);
          } */
      })
      .catch((errore) => {
        // console.log(errore);
        this.snackBar.open(
          $localize`Il recupero del file è fallito!` + errore,
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  public get language(): string {
    return this._language
  }

  public set language(language: string) {
    this._language = language
    this.hidePdfViewer = true
    // the timeout gives the PDF viewer time
    // to free memory
    setTimeout(() => {
      this.hidePdfViewer = false
    }, 1000)
  }

  get audioVideo(): boolean {
    return (
      this.documento?.tipo?.startsWith('video/') ||
      this.documento?.tipo?.startsWith('audio/')
    )
  }

  get videoKey(): string {
    // se il documento è un video, restituisce il codice del video
    if (
      this.documento?.tipo?.startsWith('video/') ||
      this.documento?.tipo?.startsWith('audio/')
    ) {
      return this.documento?.hash
    }
    return undefined
  }

  get tipoMedia(): string {
    return this.documento?.tipo
  }

  get mediaBlob(): Blob {
    // se il documento è un video, restituisce il codice del video
    if (
      this.documento?.tipo?.startsWith('video/') ||
      this.documento?.tipo?.startsWith('audio/')
    ) {
      return this.documento['file']
    }
    return undefined
  }

  get base64Raw(): string {
    const contenuto = this.documento?.base64;
    if (!contenuto) {
      return '';
    }
    // se c'è la virgola vuol dire che è un base64 con header e lo debbo rimuovere
    if (contenuto.indexOf(',') !== -1) {
      return contenuto.split(',')[1];
    }
    return contenuto;
  }
}
