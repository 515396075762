import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { NgForm, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'

import { MatChipInput, MatChipInputEvent } from '@angular/material/chips'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs'
import { Router } from '@angular/router'
import {
  AngularEditorComponent,
  AngularEditorConfig,
} from '@kolkov/angular-editor'

import { Subscription, Observable } from 'rxjs'
import { ComponentCanDeactivate } from '../can-deactivate/can-deactivate.component'
import { ElencoAllegatiComponent } from '../elenco-allegati/elenco-allegati.component'
import { Azienda } from '../modello/azienda'
import { AzioniFollowUp } from '../modello/azioni-follow-up'
import { DirittiLavorazione } from '../modello/diritti-lavorazione'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'
import { Documento } from '../modello/documento'
import { InibizioneUtenteGestoreSuSegnalazione } from '../modello/inibizione-utente-gestore-su-segnalazione'
import { Lavorazione } from '../modello/lavorazione'
import { LavorazioneMaster } from '../modello/lavorazione-master'
import { Odv } from '../modello/odv'
import { OdvAzienda } from '../modello/odv-azienda'
import { SorgenteSegnalazione } from '../modello/sorgente-segnalazione'
import { Tema } from '../modello/tema'
import { User } from '../modello/user'
import { AuthService } from '../servizi/auth.service'
import { AziendeService } from '../servizi/aziende.service'
import { AzioniFollowUpService } from '../servizi/azioni-follow-up.service'
import { ChatService } from '../servizi/chat.service'
import { DocumentiService } from '../servizi/documenti.service'
import {
  LavorazioneService,
  NotificaModificaDirittoLavorazione,
} from '../servizi/lavorazione.service'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { SorgentiSegnalazioneService } from '../servizi/sorgenti-segnalazione.service'
import { TemiService } from '../servizi/temi.service'
import { ThemeService } from '../servizi/theme.service'
import { SessionData } from '../sessione/dati-sessione'
import { MessaggiStore } from '../stores/messaggi.store'
import { ESITO_OK, Esito, ESITO_IN_PROGRESS } from '../utility/esito'
import {
  gestoreLavorazioneAbilitato,
  isMembroDiGruppo,
  isUtenteMembrodiOdvSceltoDaSegnalante,
  editabile,
  destinatarioEditabile,
  corpoSegnalazioneEditabile,
  segnalante,
  isGestoreSegnalazione,
  puoModificareLavorazione,
  gestoreLavorazione,
  coGestoreLavorazione,
  isGestoreLavorazione,
  isGestoreOCogestoreLavorazione,
  isPresaInCarico,
  promuoviStatoLavorazione,
  prossimoStatoLavorazione,
  iconaTransizione,
  puoAprireLavorazioni,
  puoChattareConSegnalante,
  gruppoOdvCoinvolto,
  isOdvGestoreSegnalazione,
  isOdvSceltoDaSegnalanteByOdvName,
  puoConsultareLavorazione,
  gruppoGestoreLavorazioneUtente,
  getStringaStato,
} from '../utility/helper-segnalazioni'
import {
  CHIUSA_INDEX,
  INAMMISSIBILE_INDEX,
  NON_COMPETENTE_INDEX,
  ALCUNE_LAVORAZIONI_CHIUSE_INDEX,
  apriChatConSegnalanteOGestore,
  statoLavorazioneLeggibile,
  openDialogGenerica,
} from '../utility/utility'
import { ConfermaScondivisioneSegnalazioneDialogComponent } from './pannello-lavorazione-segnalazione/conferma-scondivisione-segnalazione.dialog.component'
import { DirittiInoltroDialogComponent } from './pannello-lavorazione-segnalazione/diritti-inoltro.dialog.component'

@Component({
  selector: 'app-pannello-lavorazione-segnalazione',
  templateUrl: './pannello-lavorazione-segnalazione.component.html',
  styleUrls: ['./pannello-lavorazione-segnalazione.component.scss'],
})
export class PannelloLavorazioneSegnalazioneComponent
  extends ComponentCanDeactivate
  implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  form: NgForm[]
  @ViewChildren('fgestore') ngFormGestore: QueryList<NgForm>
  @ViewChild('allegatiGestore', { static: false })
  elencoAllegatiGestore: ElencoAllegatiComponent

  @ViewChild('inputFollowUp', { static: false }) inputFollowUp: ElementRef
  @ViewChild('editorNoteFollowUp', { static: true })
  editorNoteFollowUp: AngularEditorComponent

  // @ViewChild('inputFollowUp', { static: false, read: MatAutocompleteTrigger }) autoTriggerFollowUp: MatAutocompleteTrigger

  @ViewChild('tabs', { static: false }) tabGroup!: MatTabGroup

  @Input() readOnly: boolean

  saving: boolean

  lavorazioni: Lavorazione[]

  dirittiSegnalazione: DirittiSegnalazione[]

  indiceLavorazioneSelezionata: number
  idLavorazioneSelezionata: number

  formNoteSegnalazione: UntypedFormGroup

  destinatariSegnalazione: Odv[]
  destinatariSegnalazioneFiltrati: Odv[]

  destinatariSegnalazioneAziende: Azienda[]

  sorgentiSegnalazione: SorgenteSegnalazione[]
  temiSegnalazione: Tema[]
  azioniFollowUp: AzioniFollowUp[]
  azioniFollowUpFiltrate: AzioniFollowUp[]
  azioneSelezionata = false
  sottoscrizioneNotifichePerNoteCondivise: any
  sottoscrizioneNotifichePerNoteScondivise: any

  sottoscrizioni: Subscription[] = []

  consultazioneSegnalazione = 1
  comunicazioneConSegnalante = 1
  consultazioneLavorazione = 1
  aperturaLavorazioni = 1

  loading = true

  nomeOdvOmissisInModifica: string

  utentiInibitiPerSegnalazione: InibizioneUtenteGestoreSuSegnalazione[]

  editorConfigTb: AngularEditorConfig = {
    showToolbar: true,
    toolbarHiddenButtons: [[], ['insertImage', 'insertVideo']],
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize`Inserisci testo qui...`,
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }

  editorConfigNoTb: AngularEditorConfig = {
    showToolbar: false,
    editable: false,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize`Inserisci testo qui...`,
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }
  dialogRefDirittiInoltro: any
  sottoscrizioneAggiuntaDocumento: any
  lavorazioneSelezionata: Lavorazione
  dialogRefConfermaVisibilita: any
  dialogRefConfermaCambioVisibilitaSegnalazione: any
  dialogRefConfermaScondivisioneSegnalazione: any
  sorgentePredefinita: SorgenteSegnalazione


  constructor(
    public dialog: MatDialog,
    public segnalazioniService: SegnalazioneService,
    private router: Router,
    private sorgenteSegnalazioneService: SorgentiSegnalazioneService,
    private fb: UntypedFormBuilder,
    private temiService: TemiService,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    private azioniFollowUpService: AzioniFollowUpService,
    private chatService: ChatService,
    private lavorazioneService: LavorazioneService,
    private aziendeService: AziendeService,
    private documentiService: DocumentiService,
    private sessionData: SessionData,
    private messaggiStore: MessaggiStore
  ) {
    super()
  }

  ngOnInit() {
    if (!this.segnalazione) {
      this.router.navigate(['home'])
      return
    }
    this.loading = true
    if (this.segnalazione?.id) {
      this.recuperaDirittiSegnalazione()
    }

    this.recuperaTutteLeSorgentiSegnalazione()
    this.recuperaTuttiITemi()
  }




  async attivaSottoscrizionePerModificheLavorazioni() {
    this.sottoscrizioni.push(
      this.lavorazioneService.notificaPerAggiornamentiLavorazione.subscribe(
        (idLavorazione: number) => {
          console.log('notificaPerAggiornamentiLavorazione')
          const lavorazione = this.lavorazioni.find(
            (lav) => +lav.id === +idLavorazione
          )
          const gestore = gestoreLavorazioneAbilitato(lavorazione)
          if (!gestore) {
            console.log(
              'attivaSottoscrizionePerModificheLavorazioni non possibile per lavorazione con id: ' +
                idLavorazione
            )
            return
          }
          this.lavorazioneService
            .recuperaLavorazioneByIdEdOdv(
              idLavorazione,
              gestore ? gestore.id : undefined
            )
            .then((esito) => {
              if (esito.esito === ESITO_OK) {
                const tmpLav = JSON.parse(esito.payload) as Lavorazione

                this.lavorazioni.splice(
                  this.lavorazioni.findIndex(
                    (ind) => +ind.id === +idLavorazione
                  ),
                  1,
                  tmpLav
                )

                if (
                  this.lavorazioni.length > this.indiceLavorazioneSelezionata
                ) {
                  this.updateFormGestore(
                    this.lavorazioni[this.indiceLavorazioneSelezionata]
                  )
                }
              } else {
                this.snackBar.open(
                  $localize`Non è stato possibile recuperare la lavorazione aggiornata.
            ${esito.descrizioneEsito}`,
                  null,
                  {
                    duration: 4000,
                  }
                )
              }
            })
            .catch((error) => {
              this.snackBar.open(
                $localize`Non è stato possibile recuperare la lavorazione aggiornata.
          ${error}`,
                null,
                {
                  duration: 4000,
                }
              )
            })
        }
      )
    )

    this.sottoscrizioni.push(
      this.lavorazioneService.notifichePerStatoLavorazioneAggiornato.subscribe(
        (pacchetto) => {
          console.log(
            'Cambiato lo stato della lavorazione con id ' +
              pacchetto['idLavorazione']
          )
          console.log('Lo stato adesso è ' + pacchetto['stato'])
          const lavoraz = this.lavorazioni.find(
            (lavorazione) => lavorazione.id === +pacchetto['idLavorazione']
          )
          lavoraz.stato = pacchetto['stato']?.toString()
        }
      )
    )

    this.sottoscrizioni.push(
      this.lavorazioneService.notificaPerCondivisioneLavorazioni.subscribe(
        (dirittiLav: DirittiLavorazione) => {
          if (!dirittiLav.lavorazione) {
            console.log(
              'esco da notificaPerCondivisioneLavorazioni a causa di lavorazione undefined, potrebbe essere un problema'
            )
            return
          }
          console.log(
            'Mi è stata condivisa la lavorazione con id ' +
              dirittiLav.lavorazione.id
          )

          const lavTmp = dirittiLav.lavorazione

          const indice = lavTmp.dirittiOdv.findIndex(
            (diritto) => +diritto.id === +dirittiLav.id
          )
          const tempLav = this.lavorazioni.find(
            (lavorazione) =>
              lavorazione && lavorazione.id === dirittiLav.lavorazione.id
          )
          dirittiLav.lavorazione = undefined
          lavTmp.dirittiOdv.splice(indice, 1, dirittiLav)

          if (tempLav) {
            tempLav.dirittiOdv.push(dirittiLav)
          } else {
            //  recupera lavorazione dal backend
            this.recuperaLavorazione(lavTmp.id).then((lavo) => {
              console.log('pushing ' + lavo ? lavo.id : 'null')
              this.lavorazioni.push(lavo)
              // elimina eventuali duplicati
              this.lavorazioni = this.lavorazioni.filter(
                (lavorazione, index, self) =>
                  index === self.findIndex((t) => t.id === lavorazione.id)
              )
              this.selezionaTabByIdLavorazioneSelezionata()
              // this.lavorazioneService.sottoscriviPerModificheLavorazione(lavo.id).then();
              this.lavorazioneService
                .sottoscriviPerModificheDirittiLavorazione(lavo)
                .then()
            })

            console.log('Elenco diritti vuoto check!!!.')
          }

          this.lavorazioneService
            .sottoscriviPerModificheLavorazione(lavTmp.id)
            .then()
        }
      )
    )

    this.sottoscrizioni.push(
      this.lavorazioneService.notificaPerScondivisioneLavorazioni.subscribe(
        ({ idLavorazione, idOdv }) => {
          console.log(
            'Mi è stata Scondivisa la lavorazione con id ' + idLavorazione
          )
          console.log('Mi è stata Scondivisa per il gruppo con id ' + idOdv)
          const indice = this.lavorazioni.findIndex(
            (lavorazione) => +lavorazione.id === +idLavorazione
          )
          let diritti
          if (indice !== -1) {
            diritti = this.lavorazioni[indice].dirittiOdv.filter(
              (dir) =>
                +dir.odv.id !== +idOdv &&
                isMembroDiGruppo(this.authService.getUser(), dir.odv)
            )
          }
          if (indice !== -1 && diritti.length === 0) {
            this.lavorazioni.splice(indice, 1)
          }
        }
      )
    )

    this.sottoscrizioni.push(
      this.segnalazioniService.notifichePerSegnalazioneDisinoltrata.subscribe(
        (segnalazione) => {
          if (
            segnalazione.id === this.sessionData.getSegnalazioneSelezionata().id &&
            !isUtenteMembrodiOdvSceltoDaSegnalante(
              segnalazione,
              this.authService.getUser()
            )
          ) {
            this.snackBar.open(
              $localize`Non hai più accesso alla segnalazione con id ${segnalazione.id}
        ed avente ad oggetto ${segnalazione.oggetto}`,
              null,
              {
                duration: 4000,
              }
            )
            this.router.navigate(['home'])
          }
        }
      )
    )

    this.sottoscrizioni.push(
      this.lavorazioneService.notifichePerStatoLavorazioneAggiornato.subscribe(
        ({ idLavorazione, stato }) => {
          const lav = this.lavorazioni.find(
            (lavora) => +lavora.id === +idLavorazione
          )
          if (lav) {
            lav.stato = stato?.toString()
          }
        }
      )
    )

    this.sottoscrizioni.push(
      this.lavorazioneService.notificaPerAggiornamentiDirittoLavorazione.subscribe(
        (notifica: NotificaModificaDirittoLavorazione) => {
          console.log('Notifica per variazione dirittoLavorazione...')
          const dirittoLavorazione = notifica.dirittoLavorazione
          const idLavorazione = notifica.idLavorazione
          const idDirittoLavorazione = notifica.idDirittoLavorazione
          if (dirittoLavorazione) {
            dirittoLavorazione.lavorazione = undefined
          }

          this.lavorazioni.forEach((lavorazione) => {
            const indice = lavorazione.dirittiOdv.findIndex(
              (diritto) => +diritto.id === +idDirittoLavorazione
            )
            if (indice !== -1) {
              if (dirittoLavorazione) {
                lavorazione.dirittiOdv.splice(indice, 1, dirittoLavorazione)
              } else {
                lavorazione.dirittiOdv.splice(indice, 1)
              }
            } else if (idLavorazione) {
              //  forse è una lavorazione non inclusa nell'elenco
              const ind = this.lavorazioni.findIndex(
                (lav) => +lav.id === +idLavorazione
              )
              if (ind === -1) {
                //  recupera lavorazione dal backend
                this.recuperaLavorazione(idLavorazione).then((lavo) => {
                  console.log('pushing ' + lavo ? lavo.id : 'null')
                  this.lavorazioni.push(lavo)
                  // elimina eventuali duplicati
                  this.lavorazioni = this.lavorazioni.filter(
                    (lav, index, self) =>
                      index === self.findIndex((t) => t.id === lav.id)
                  )
                })
              }
            } else {
              // lavorazione.dirittiOdv.push(dirittoLavorazione);
              console.warn(
                'Aggiornamento dirittolavorazione non ha trovato lavorazioni da aggiornare!!!'
              )
            }
            this.aggiornaStatoForm(
              this.lavorazioni?.[this.indiceLavorazioneSelezionata]
            )
          })
        }
      )
    )

    this.sottoscrizioni.push(
      this.segnalazioniService.notificaPerAggiornamentiDirittoSegnalazione.subscribe(
        (dirittoSegnalazione) => {
          console.log('Notifica per variazione dirittiSegnalazione...')
          // aggiorno l'elenco dei diritti
          this.dirittiSegnalazione.splice(
            this.dirittiSegnalazione.findIndex(
              (dir) => dir.id === dirittoSegnalazione.id
            ),
            1,
            dirittoSegnalazione
          )
          if (this.segnalazione.dirittiSegnalazioneOdv) {
            const indice = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
              (diritto) => +diritto.id === +dirittoSegnalazione.id
            )
            if (indice !== -1) {
              this.segnalazione.dirittiSegnalazioneOdv.splice(
                indice,
                1,
                dirittoSegnalazione
              )
              this.recuperaLavorazioni(this.segnalazione.id).then(() => {
                this.selezionaTabByIdLavorazioneSelezionata()
              })
            }
          }
        }
      )
    )

    this.sottoscrizioneAggiuntaDocumento =
      this.documentiService.aggiungiDocumento.subscribe((epdoc) => {
        if (epdoc.tipoEntitaPrincipale !== 'lavorazione') {
          return
        }

        //  Attenzione questa lavorazione non è locale ma byRef è legata all'array lavorazioni del componente!!!
        const lavorazione = epdoc.entitaPrincipale as Lavorazione

        if (lavorazione.id != null) {
          this.documentiService
            .collegaDocumentoALavorazione(epdoc.documento, lavorazione)
            .then((esito: Esito) => {
              if (esito.esito === ESITO_OK) {
                //  individua la lavorazione nell'array globale
                // const lavorazioneToBeUpdated = this.lavorazioni.find(lav => lav.id === lavorazione.id);
                if (lavorazione.documenti === undefined) {
                  lavorazione.documenti = []
                }
                lavorazione.documenti?.push(JSON.parse(esito.payload))
                // this.elencoAllegatiGestore.documenti = lavorazione.documenti
                // this.elencoAllegatiGestore.refresh()
              } else {
                this.snackBar.open(
                  $localize`Il salvataggio della nota è fallito! ` +
                    esito.descrizioneEsito,
                  null,
                  {
                    duration: 6000,
                  }
                )
              }
            })
            .catch((errore) => {
              this.snackBar.open(
                $localize`Il salvataggio della nota è fallito! ` + errore,
                null,
                {
                  duration: 6000,
                }
              )
            })
        } else {
          this.segnalazioniService.notificaPerDocumentoAggiunto.next({
            canale: 'documento-aggiunto-lavorazione-' + lavorazione.id,
            documento: epdoc.documento,
          })
        }
      })
  }
  async recuperaLavorazione(idLavorazione: number): Promise<Lavorazione> {
    const lavorazione = await this.lavorazioneService
      .recuperaLavorazioneById(idLavorazione)
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          return JSON.parse(esito.payload)
        }
        return undefined
      })
    return lavorazione
  }

  aggiornaNoteCondivise(): any {
    console.log('aggiornaNoteCondivise: NOT IMPLEMENTED')
  }

  public ngOnDestroy(): void {
    this.sottoscrizioni.forEach((sottoscrizione) =>
      sottoscrizione.unsubscribe()
    )

    if (this.sottoscrizioneNotifichePerNoteCondivise) {
      this.sottoscrizioneNotifichePerNoteCondivise.unsubscribe()
    }
    if (this.sottoscrizioneNotifichePerNoteScondivise) {
      this.sottoscrizioneNotifichePerNoteScondivise.unsubscribe()
    }
    this.sottoscrizioneAggiuntaDocumento?.unsubscribe()
  }

  ngAfterViewInit(): void {
    // this.tabGroup.selectedIndex = 0
    if (
      this.authService.getUser()?.odv &&
      this.segnalazione &&
      this.segnalazione.id
    ) {
      this.recuperaLavorazioni(this.segnalazione.id).then(() => {
        // se ho almeno una lavorazione seleziono il tab corrispondente
        if (this.lavorazioni && this.lavorazioni.length > 0) {
          // chiama doSelectTab tra 0.5 e 1 secondo dopo il caricamento della pagina
          // per evitare che il tab venga selezionato prima che sia stato caricato
          setTimeout(() => {
            this.doSelectTab(0)
          }, 500)
        }
        this.recuperaTutteLeAzioniFollowUp()
        this.recuperaTuttiIDestinatari()
        this.attivaSottoscrizionePerModificheLavorazioni().then(() => {
          this.createForm()
          if (this.lavorazioni && this.lavorazioni.length > 0) {
            this.idLavorazioneSelezionata = this.lavorazioni[0].id
            this.selezionaTabByIdLavorazioneSelezionata()

            this.recuperaTutteLeAzioniFollowUp()
          }
          this.segnalazioniService.notificaPerAggiornamentoFormLavorazione.next(
            this.ngFormGestore
          )
          this.loading = false
          this.initTabClickHandler()
        })
      })
    }
  }

  public initTabClickHandler() {
    // Just to be sure

    setTimeout(() => {
      // Returning just a boolean did nothing, but this works:

      // 1. Save the click handler for later
      const handleTabClick = this.tabGroup._handleClick

      // 2. Replace the click handler with our custom function
      this.tabGroup._handleClick = (tab, header, index) => {
        // 3. Implement your conditional logic in canDeactivateTab()
        // (return the boolean here)
        if (this.canDeactivateTab(index)) {
          // 4. If the tab *should* be changed, call the 'old' click handler
          handleTabClick.apply(this.tabGroup, [tab, header, index])
        }
      }
    }, 10)
  }

  public canDeactivateTab(index): boolean {
    if (this.formNoteSegnalazione.dirty) {
      console.log('Non dovresti uscire perdi le modifiche....')
      openDialogGenerica(
        this.dialog,
        $localize`Modifiche non salvate`,
        '',
        $localize`Hai delle modifiche non salvate, confermi di voler uscire?`,
        () => {
          this.formNoteSegnalazione.reset()
          this.indiceLavorazioneSelezionata = index
          this.idLavorazioneSelezionata =
            this.lavorazioni[this.indiceLavorazioneSelezionata] != null
              ? this.lavorazioni[this.indiceLavorazioneSelezionata].id
              : 0
          this.lavorazioneSelezionata =
            this.lavorazioni[this.indiceLavorazioneSelezionata]
          this.selezionaTabByIndiceTab()
        },
        () => {}
      )
      return
    }
    return true
  }

  recuperaTutteLeSorgentiSegnalazione(): any {
    this.sorgenteSegnalazioneService
      .recuperaSorgentiSegnalazione()
      .then((esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nel recupero delle sorgenti segnalazione`,
            null,
            {
              duration: 6000,
            }
          )

          return
        }
        const data = JSON.parse(esito.payload)
        this.sorgentiSegnalazione = data
        const linguaUtente = this.authService.getLinguaUtente()
        this.sorgentiSegnalazione = this.sorgentiSegnalazione.filter(
          (sorgente) => sorgente.lingua === linguaUtente
        )
        this.sorgentePredefinita = this.sorgentiSegnalazione.find(
          (sorgente) => sorgente.predefinita
        )
      })
  }

  recuperaDirittiSegnalazione(): any {
    if (isNaN(+this.segnalazione.id)) {
      return
    }
    this.segnalazioniService
      .recuperaDirittiSegnalazioneOdv(+this.segnalazione.id)
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.dirittiSegnalazione = JSON.parse(esito.payload)
          //  elimina i diritti sui quali non si ha visibilità solo se sono un utente membro di
          //  gruppi non coinvolti dal segnalante
          if (
            !isUtenteMembrodiOdvSceltoDaSegnalante(
              this.segnalazione,
              this.authService.getUser()
            )
          ) {
            this.dirittiSegnalazione = this.dirittiSegnalazione.filter(
              (diritto) =>
                isMembroDiGruppo(
                  this.authService.getUser(),
                  diritto.odvCheHaInoltrato
                ) ||
                isMembroDiGruppo(
                  this.authService.getUser(),
                  diritto.odvDestinatario
                ) ||
                this.dirittoDelGruppoCheMiHaInoltratoLaSegnalazione(
                  diritto,
                  this.dirittiSegnalazione
                )
            )
          }
        } else {
          this.snackBar.open(
            $localize`Recupero diritti segnalazione Fallito. Errore: ` +
              esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero diritti segnalazione Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  dirittoDelGruppoCheMiHaInoltratoLaSegnalazione(
    diritto: DirittiSegnalazione,
    dirittiSegnalazione: DirittiSegnalazione[]
  ) {
    const result = dirittiSegnalazione.find((dir) =>
      isMembroDiGruppo(this.authService.getUser(), dir.odvDestinatario)
    )
    return diritto.odvDestinatario?.id === result?.odvCheHaInoltrato?.id
  }

  recuperaTuttiITemi(): any {
    this.temiService.recuperaTemi().then((esito) => {
      if (esito.esito === ESITO_OK) {
        const data = JSON.parse(esito.payload)
        this.temiSegnalazione = data
        const linguaUtente = this.authService.getLinguaUtente()
        this.temiSegnalazione = this.temiSegnalazione.filter(
          (tema) => tema.lingua === linguaUtente
        )
        // ora imposto il form control dei temi
        if (this.indiceLavorazioneSelezionata >= 0) {
          this.formNoteSegnalazione.controls.tema.setValue(
            this.lavorazioneSelezionata?.lavorazioneMaster?.tema
          )
        }
      }
    })
  }

  recuperaTutteLeAzioniFollowUp(): any {
    // console.log('-6-');
    // invocalo solo se non è già stato invocato
    if (this.azioniFollowUp && this.azioniFollowUp.length > 0) {
      return
    }
    this.azioniFollowUpService.recuperaAzioniFollowUp().then((esito) => {
      if (esito.esito === ESITO_OK) {
        const data = JSON.parse(esito.payload)
        this.azioniFollowUp = data
        this.azioniFollowUpFiltrate = data
        //  Filtra per lingua
        const linguaUtente = this.authService.getLinguaUtente()
        this.azioniFollowUpFiltrate = this.azioniFollowUpFiltrate.filter(
          (azione) => azione.lingua === linguaUtente
        )
        // ora imposto il form control delle azioni follow up
        if (this.indiceLavorazioneSelezionata >= 0) {
          this.formNoteSegnalazione.controls.azioniFollowUpForm.setValue(
            this.lavorazioni[this.indiceLavorazioneSelezionata].azioniFollowUp
          )
        }
      } else if (esito.esito === ESITO_IN_PROGRESS) {
        console.log('Recupero azioni follow up in corso...')
      } else {
        this.snackBar.open(
          $localize`Errore nel recupero delle azioni follow up`,
          null,
          {
            duration: 6000,
          }
        )
      }
    })
  }

  recuperaTuttiIDestinatari(): any {
    this.destinatariSegnalazione = []
    this.segnalazioniService
      .recuperaGruppiGestori()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.destinatariSegnalazione = JSON.parse(esito.payload)
          this.destinatariSegnalazioneFiltrati = this.destinatariSegnalazione
          //  Ora elimina quelle già selezionate
          this.destinatariSegnalazioneFiltrati =
            this.destinatariSegnalazioneFiltrati.filter((destinatario) => {
              let res = true
              if (
                this.lavorazioni &&
                this.indiceLavorazioneSelezionata &&
                this.indiceLavorazioneSelezionata < this.lavorazioni.length &&
                this.lavorazioni[this.indiceLavorazioneSelezionata].dirittiOdv
              ) {
                res =
                  this.lavorazioni[
                    this.indiceLavorazioneSelezionata
                  ].dirittiOdv.find(
                    (diritto) => diritto.odv.nomeOdv === destinatario.nomeOdv
                  ) === undefined
              }
              return res
            })
        } else {
          this.snackBar.open(
            $localize`Recupero elenco destinatari Fallito. Errore: ` +
              esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco destinatari Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })

    this.aziendeService
      .recuperaAziendeConOdv()
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.destinatariSegnalazioneAziende = JSON.parse(esito.payload)
          this.filtraDestinatari()
        } else {
          this.snackBar.open(
            $localize`Recupero elenco destinatari aziendali Fallito. Errore: ` +
              esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco destinatari aziendali Fallito. Errore: ` +
            error,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  async recuperaLavorazioni(idSegnalazione): Promise<any> {
    this.destinatariSegnalazione = []
    return this.lavorazioneService
      .recuperaLavorazioniBySegnalazioneEdUtente(idSegnalazione)
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.lavorazioni = JSON.parse(esito.payload) as Lavorazione[]
          let indiceLavorazioneDaGestore = -1
          if (this.gestore()) {
            indiceLavorazioneDaGestore = this.lavorazioni.findIndex(
              (lavorazione) =>
                lavorazione.dirittiOdv.find((diritto) => diritto.gestore) !==
                undefined
            )
          }
          if (indiceLavorazioneDaGestore !== -1) {
            this.lavorazioneService.sottoscriviPerModificheLavorazione(
              this.lavorazioni[indiceLavorazioneDaGestore].id
            )
          }
          this.lavorazioni.forEach((lavorazione) => {
            this.lavorazioneService.sottoscriviPerModificheLavorazione(
              lavorazione.id
            )
            this.lavorazioneService.sottoscriviPerModificheDirittiLavorazione(
              lavorazione
            )
          })
          // if (this.lavorazioneSelezionata) {
          this.filtraDestinatari()
        } else {
          // this.lavorazioneSelezionata = undefined;
          this.snackBar.open(
            $localize`Recupero Lavorazione Fallito. Errore: ` +
              esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        console.log(error)
        this.snackBar.open(
          $localize`Recupero Lavorazione Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  filtraDestinatari() {
    if (this.destinatariSegnalazioneAziende) {
      this.destinatariSegnalazioneAziende.forEach((azienda) => {
        azienda.odvAzienda.forEach((odvAzienda) => {
          odvAzienda['checked'] = false
          if (this.segnalazione && this.segnalazione.dirittiSegnalazioneOdv && odvAzienda.odv) {
            this.segnalazione.dirittiSegnalazioneOdv.forEach((diritto) => {
              if (diritto.odvDestinatario.nomeOdv === odvAzienda.odv.nomeOdv) {
                odvAzienda['checked'] = true
              }
            })
          }
        })
      })
    }
  }

  aggiungiDestinatario(idOdv: number) {
    this.destinatariSegnalazioneAziende.forEach((azienda) => {
      const odvAzienda = azienda.odvAzienda.find(
        (odvAz) => odvAz.odv.id === idOdv
      )
      if (odvAzienda) {
        odvAzienda['checked'] = true
      }
    })
  }

  rimuoviDestinatario(idOdv: number) {
    this.destinatariSegnalazioneAziende.forEach((azienda) => {
      const odvAzienda = azienda.odvAzienda.find(
        (odvAz) => odvAz.odv.id === idOdv
      )
      if (odvAzienda) {
        odvAzienda['checked'] = false
      }
    })
  }

  private createForm() {
    //  Form Note gestore

    this.formNoteSegnalazione = this.fb.group({
      stato: '',
      sorgente: this.fb.control(this.sorgentePredefinita),
      ammissibile: this.fb.control(''),
      visibileAlSegnalante: this.fb.control(''),
      motivoNonAmmissibilita: this.fb.control(''),
      tema: this.fb.control(''),
      azioniFollowUpForm: this.fb.control({ value: null, disabled: true }),
      noteFollowUp: this.fb.control(''),
      dirittiOdv: this.fb.control({ value: '', disabled: true }),
      eliminareSegnalazione: this.fb.control(''),
      dataDecisioneEliminazione: this.fb.control({ value: '', disabled: true }),
    })

    if (!this.readOnly) {
      // this.formNoteSegnalazione.controls.azioniFollowUpForm.valueChanges
      //   .pipe(debounceTime(400))
      //   .subscribe((data) => {
      //     if (
      //       !this.azioneSelezionata &&
      //       this.formNoteSegnalazione.controls.azioniFollowUpForm.dirty &&
      //       data &&
      //       data.toString().length > 0
      //     ) {
      //       this.azioniFollowUpService
      //         .recuperaAzioniFollowUpByName(data)
      //         .then((esito) => {
      //           if (esito.esito === ESITO_OK) {
      //             this.azioniFollowUpFiltrate = JSON.parse(esito.payload)
      //             // Elimino quelle in una lingua diversa da quella dell'utente
      //             const linguaUtente = this.authService.getLinguaUtente()
      //             this.azioniFollowUpFiltrate =
      //               this.azioniFollowUpFiltrate.filter(
      //                 (azione) => azione.lingua === linguaUtente
      //               )
      //             //  Ora elimina quelle già selezionate
      //             this.azioniFollowUpFiltrate =
      //               this.azioniFollowUpFiltrate.filter((azione) => {
      //                 let res =
      //                   this.lavorazioni &&
      //                   +this.indiceLavorazioneSelezionata >= 0 &&
      //                   +this.indiceLavorazioneSelezionata <
      //                     +this.lavorazioni.length
      //                 res =
      //                   res &&
      //                   this.lavorazioni[
      //                     this.indiceLavorazioneSelezionata
      //                   ].azioniFollowUp.find(
      //                     (azionetmp) => azionetmp.azione === azione.azione
      //                   ) === undefined
      //                 return res
      //               })
      //           } else {
      //             this.snackBar.open(
      //               $localize`Recupero Azioni Follow Up Fallito. Errore: ` +
      //                 esito.descrizioneEsito,
      //               null,
      //               {
      //                 duration: 4000,
      //               }
      //             )
      //           }
      //         })
      //     } else {
      //       // console.log('-2-');
      //       // this.recuperaTutteLeAzioniFollowUp();
      //       this.azioneSelezionata = false
      //     }
      //   })
      // this.formNoteSegnalazione.controls.sorgente.valueChanges
      //   .pipe(debounceTime(400))
      //   .subscribe((data) => {
      //     if (
      //       this.formNoteSegnalazione.controls.sorgente.dirty &&
      //       data &&
      //       data.toString().length > 0
      //     ) {
      //       this.sorgenteSegnalazioneService
      //         .recuperaSorgentiSegnalazioneByName(data)
      //         .then((esito) => {
      //           if (esito.esito !== ESITO_OK) {
      //             this.snackBar.open(
      //               $localize`Recupero Sorgenti Segnalazione Fallito. Errore: ` +
      //                 esito.descrizioneEsito,
      //               null,
      //               {
      //                 duration: 4000,
      //               }
      //             )
      //             return
      //           }
      //           this.sorgentiSegnalazione = JSON.parse(esito.payload)
      //           //  Filtra per lingua
      //           const linguaUtente = this.authService.getLinguaUtente()
      //           this.sorgentiSegnalazione = this.sorgentiSegnalazione.filter(
      //             (azione) => azione.lingua === linguaUtente
      //           )
      //         })
      //     } else {
      //       this.recuperaTutteLeSorgentiSegnalazione()
      //     }
      //   })
      // this.formNoteSegnalazione.controls.tema.valueChanges
      //   .pipe(debounceTime(400))
      //   .subscribe((data) => {
      //     if (
      //       this.formNoteSegnalazione.controls.tema.dirty &&
      //       data &&
      //       data.toString().length > 0
      //     ) {
      //       this.temiService.recuperaTemiByName(data).then((esito) => {
      //         if (esito.esito !== ESITO_OK) {
      //           this.snackBar.open(
      //             $localize`Recupero Temi Segnalazione Fallito`,
      //             null,
      //             {
      //               duration: 4000,
      //             }
      //           )
      //           return
      //         }
      //         const response = JSON.parse(esito.payload)
      //         this.temiSegnalazione = response
      //         //  Filtra per lingua
      //         const linguaUtente = this.authService.getLinguaUtente()
      //         this.temiSegnalazione = this.temiSegnalazione.filter(
      //           (azione) => azione.lingua === linguaUtente
      //         )
      //       })
      //     } else {
      //       this.recuperaTuttiITemi()
      //     }
      //   })
    }
  }

  updateFormGestore(lavorazione: Lavorazione) {
    // this.idLavorazioneSelezionata = lavorazione.id;

    this.formNoteSegnalazione.controls.stato.setValue(
      lavorazione ? lavorazione.stato : ''
    )

    this.formNoteSegnalazione.controls.azioniFollowUpForm.setValue(
      lavorazione.azioniFollowUp
    )

    const sorgente =
      lavorazione &&
      lavorazione.lavorazioneMaster &&
      lavorazione.lavorazioneMaster.sorgente
        ? lavorazione.lavorazioneMaster.sorgente
        : this.sorgentePredefinita
    this.formNoteSegnalazione.controls.sorgente.setValue(sorgente)
    // imposta dirty il form
    if (!lavorazione.lavorazioneMaster || !lavorazione.lavorazioneMaster.sorgente) {
      this.formNoteSegnalazione.controls.sorgente.markAsDirty()
      lavorazione.lavorazioneMaster.sorgente = sorgente
    }

    this.formNoteSegnalazione.controls.ammissibile.setValue(
      lavorazione && lavorazione.ammissibile === false ? 'false' : 'true'
    )

    this.formNoteSegnalazione.controls.motivoNonAmmissibilita.setValue(
      lavorazione && lavorazione.motivoNonAmmissibilita
        ? lavorazione.motivoNonAmmissibilita
        : ''
    )

    this.formNoteSegnalazione.controls.visibileAlSegnalante.setValue(
      lavorazione && lavorazione.visibileAlSegnalante === false
        ? 'false'
        : 'true'
    )

    this.formNoteSegnalazione.controls.tema.setValue(
      lavorazione &&
        lavorazione.lavorazioneMaster &&
        lavorazione.lavorazioneMaster.tema
        ? lavorazione.lavorazioneMaster.tema
        : ''
    )

    this.formNoteSegnalazione.controls.noteFollowUp.setValue(
      lavorazione ? lavorazione.noteFollowUp : ''
    )

    this.formNoteSegnalazione.controls.dirittiOdv.setValue(
      lavorazione ? lavorazione.dirittiOdv : ''
    )

    this.aggiornaStatoForm(lavorazione)
  }

  aggiornaStatoForm(lavorazione: Lavorazione) {
    if (
      !lavorazione ||
      !this.isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione) ||
      +lavorazione.stato === CHIUSA_INDEX
    ) {
      this.formNoteSegnalazione.disable()
    } else {
      this.formNoteSegnalazione.enable()
    }
  }

  get utente(): User {
    return this.authService.getUser()
  }

  get odv(): boolean {
    return this.authService.getUser()?.odv
  }

  get editabile(): boolean {
    return editabile(this.segnalazione)
  }

  get destinatarioEditabile(): boolean {
    return destinatarioEditabile(this.segnalazione, this.authService.getUser())
  }

  get corpoSegnalazioneEditabile(): boolean {
    return corpoSegnalazioneEditabile(this.segnalazione)
  }

  get segnalante(): boolean {
    // @ToDo da rivedere in fase di test
    return segnalante(this.segnalazione, this.authService.getUser())
  }

  gestibile(lavorazione): boolean {
    const gest =
      this.odv &&
      lavorazione.stato !== CHIUSA_INDEX.toString() &&
      lavorazione.stato !== INAMMISSIBILE_INDEX.toString() &&
      lavorazione.stato !== NON_COMPETENTE_INDEX.toString() &&
      lavorazione.dirittiOdv.find(
        (diritto) =>
          (diritto.gestore || diritto.coGestore) &&
          isMembroDiGruppo(this.authService.getUser(), diritto.odv)
      ) !== undefined
    return gest
  }

  gestore(): boolean {
    const gestoreSegna = isGestoreSegnalazione(
      this.segnalazione,
      this.authService.getUser()
    )
    return gestoreSegna
  }

  nomeGestoreLavorazione(
    dirittiSegnalazione: DirittiSegnalazione,
    lavorazione: Lavorazione
  ): string {
    // const dirittiLavorazione = this.dirittiLavorazioneDaDirittiSegnalazione(dirittiSegnalazione);
    const dirittiLavorazione = lavorazione.dirittiOdv.find(
      (diritto) => diritto.gestore
    )
    if (dirittiLavorazione) {
      if (dirittiLavorazione.gestore) {
        // return dirittiLavorazione.odv.nomeOdv;
        return 'Gestore'
      } else if (dirittiLavorazione.odvCheHaCondiviso) {
        return dirittiLavorazione.odvCheHaCondiviso?.nomeOdv.toString()
      }
    } else {
      const gestore = gestoreLavorazioneAbilitato(lavorazione)

      if (gestore && +gestore.id === +dirittiSegnalazione.odvDestinatario.id) {
        return 'Gestore'
      }
      return null
    }
  }

  possoPrendereInCaricoLavorazione(lavorazione): boolean {
    return puoModificareLavorazione(lavorazione, this.authService.getUser())
  }

  isOdvGestoreLavorazione(lavorazione: Lavorazione, odv: Odv): boolean {
    const gestore = gestoreLavorazione(lavorazione)
    if (odv && gestore) {
      // return isOdvGestoreLavorazione(lavorazione, odv);
      return +odv.id === +gestore.id
    }
    return false
  }

  isOdvGestoreOCoGestoreLavorazione(
    lavorazione: Lavorazione,
    odv: Odv
  ): boolean {
    const gestore = gestoreLavorazione(lavorazione)
    if (odv && gestore) {
      // return isOdvGestoreLavorazione(lavorazione, odv);
      return +odv.id === +gestore.id
    }
    const dirittiLavCoGestore = coGestoreLavorazione(lavorazione)
    if (odv && dirittiLavCoGestore) {
      // return isOdvGestoreLavorazione(lavorazione, odv);
      return (
        dirittiLavCoGestore.find((dlav) => +dlav.odv.id === +odv.id) !==
        undefined
      )
    }
    return false
  }

  isUtenteCorrenteGestoreLavorazione(lavorazione: Lavorazione): boolean {
    // const gestore = gestoreLavorazioneAbilitato(lavorazione);
    const result = isGestoreLavorazione(
      lavorazione,
      this.authService.getUser()
    )
    return result
  }

  isUtenteCorrenteGestoreOCogestoreLavorazione(
    lavorazione: Lavorazione
  ): boolean {
    // const gestore = gestoreLavorazioneAbilitato(lavorazione);
    const result = isGestoreOCogestoreLavorazione(
      lavorazione,
      this.authService.getUser()
    )
    return result
  }

  possoDialogareConSegnalante(): boolean {
    return (
      this.dirittiSegnalazione?.find(
        (diritto) =>
          diritto.comunicazioneConSegnalante &&
          isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario)
      ) !== undefined
    )
  }

  possoInoltrareLavorazione(lavorazione) {
    return this.isUtenteCorrenteGestoreLavorazione(lavorazione)
  }

  hoInoltratoSegnalazione(dirittiSegnalazione) {
    if (
      dirittiSegnalazione.odvCheHaInoltrato &&
      isMembroDiGruppo(
        this.authService.getUser(),
        dirittiSegnalazione.odvCheHaInoltrato
      )
    ) {
      return true
    }
    return false
  }

  odvSceltoDaSegnalante(dirittiSegnalazione: DirittiSegnalazione) {
    return dirittiSegnalazione.sceltoDaSegnalante
  }

  sonoGestoreSceltoDaSegnalante() {
    const result =
      this.segnalazione.dirittiSegnalazioneOdv.find(
        (diritti) =>
          diritti.sceltoDaSegnalante &&
          isMembroDiGruppo(this.authService.getUser(), diritti.odvDestinatario)
      ) !== undefined
    return result
  }

  gestoreDestinatarioSegnalante(dirittiSegnalazione: DirittiSegnalazione) {
    return (
      dirittiSegnalazione.odvDestinatario.id ===
      this.segnalazione.segnalante.utente.id
    )
  }

  lavorazioneChiusa(lavorazione: Lavorazione) {
    return +lavorazione.stato === +CHIUSA_INDEX
  }

  hoInoltratoIo(dirittiSegnalazione: DirittiSegnalazione): boolean {
    const result =
      dirittiSegnalazione.odvCheHaInoltrato !== null &&
      isMembroDiGruppo(
        this.authService.getUser(),
        dirittiSegnalazione.odvCheHaInoltrato
      )
    return result
  }

  presaInCarico(): boolean {
    return isPresaInCarico(this.segnalazione, this.authService.getUser())
  }

  salvaLavorazioneSegnalazione(lavorazione: Lavorazione) {
    if (!this.saving) {
      this.saving = true
      if (this.segnalazione.id == null || this.segnalazione.id.length === 0) {
        this.segnalazione.stato = ALCUNE_LAVORAZIONI_CHIUSE_INDEX.toString()
      }
      if (this.odv) {
        if (!lavorazione.lavorazioneMaster) {
          lavorazione.lavorazioneMaster = new LavorazioneMaster()
        }

        lavorazione.lavorazioneMaster.segnalazione = lavorazione.segnalazione

        //  Ammissibilità
        if (
          this.formNoteSegnalazione.controls.ammissibile.value ||
          this.formNoteSegnalazione.controls.ammissibile.value === undefined
        ) {
          lavorazione.ammissibile =
            this.formNoteSegnalazione.controls.ammissibile.value === 'true'
          lavorazione.motivoNonAmmissibilita =
            this.formNoteSegnalazione.controls.motivoNonAmmissibilita.value
        }

        if (
          this.odv &&
          (!this.formNoteSegnalazione.controls.motivoNonAmmissibilita ||
            !this.formNoteSegnalazione.controls.motivoNonAmmissibilita.value) &&
          this.formNoteSegnalazione.controls.ammissibile.value === 'false'
        ) {
          this.snackBar.open(
            $localize`Inserire il motivo per cui la segnalazione non è ammissibile`,
            $localize`Chiudi`,
            {
              duration: 5000,
            }
          )

          this.saving = false
          return
        }

        //  Visibilità
        if (
          this.formNoteSegnalazione.controls.visibileAlSegnalante.value ||
          this.formNoteSegnalazione.controls.visibileAlSegnalante.value ===
            undefined
        ) {
          lavorazione.visibileAlSegnalante =
            this.formNoteSegnalazione.controls.visibileAlSegnalante.value ===
            'true'
        }

        //  metti a posto le azioni di follow up
        if (lavorazione.azioniFollowUp) {
          for (
            let index = 0;
            index < lavorazione.azioniFollowUp.length;
            index++
          ) {
            const azione = lavorazione.azioniFollowUp[index]

            const tmpAzione = this.azioniFollowUp.find(
              (innerAzione) => innerAzione.azione === azione.azione
            )
          }
        }
        //  e le note!!!
        lavorazione.noteFollowUp =
          this.formNoteSegnalazione.controls.noteFollowUp.value

      }

      this.lavorazioneService
        .salvaLavorazione(lavorazione, +this.segnalazione.id)
        .then((esito: Esito) => {
          // console.log(result);
          if (esito.esito === ESITO_OK) {
            const tmpNotaSegnalazione = JSON.parse(esito.payload)
            lavorazione = tmpNotaSegnalazione
            const indiceLavorazione = this.lavorazioni.findIndex(
              (lav) => +lav.id === +lavorazione.id
            )
            if (indiceLavorazione !== -1) {
              this.lavorazioni.splice(indiceLavorazione, 1, lavorazione)
            }
            this.filtraDestinatari()
            if (this.formNoteSegnalazione.controls.dataDecisioneEliminazione.dirty) {
              this.segnalazioniService.notificaPerAggiornamentoDatiArchiviazione.next()
            }

            if (tmpNotaSegnalazione) {
              this.formNoteSegnalazione.markAsPristine()
              this.formNoteSegnalazione.markAsUntouched()
              this.saving = false
              // this.createForm();
              this.snackBar.open($localize`Lavorazione salvata`, null, {
                duration: 2000,
              })
            } else {
              this.saving = false
              this.snackBar.open(
                $localize`Salvataggio Fallito. Codice Errore: `,
                null,
                {
                  duration: 4000,
                }
              )
            }
          } else {
            this.snackBar.open(
              $localize`Salvataggio Fallito. `,
              esito.descrizioneEsito,
              {
                duration: 4000,
              }
            )
          }
        })
        .catch((err) => {
          this.saving = false
          // console.log(err);
          this.snackBar.open(
            $localize`Salvataggio Fallito. Codice Errore: `,
            err,
            {
              duration: 4000,
            }
          )
        })
      // console.log('-3-');
      this.recuperaTutteLeAzioniFollowUp()
    } else {
      this.snackBar.open($localize`Salvataggio in corso!`, null, {
        duration: 2000,
      })
    }
  }

  competenza(lavorazione) {
    return lavorazione?.stato !== NON_COMPETENTE_INDEX.toString()
  }

  nonDiCompetenza(lavorazione: Lavorazione) {
    lavorazione.stato = NON_COMPETENTE_INDEX.toString()
    this.lavorazioneService
      .setStatoLavorazione(lavorazione, this.segnalazione)
      .then(() => {
        this.snackBar.open(
          $localize`Cambio stato eseguito con successo`,
          null,
          {
            duration: 4000,
          }
        )

        this.segnalazione.presaInCarico = false
        this.segnalazione.gestore = null
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Cambio stato Fallito. Codice Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'Non è stato possibile promuovere lo stato della lavorazione ' +
            lavorazione.id +
            ' a non di competenza'
        )
      })
  }

  promuoviStato(lavorazione: Lavorazione) {
    const oldStato = lavorazione.stato
    // const this = this;
    promuoviStatoLavorazione(
      lavorazione,
      this.segnalazione,
      this.lavorazioneService
    )
      .then(() => {
        this.salvaLavorazioneSegnalazione(lavorazione)
        this.aggiornaStatoForm(
          this.lavorazioni[this.indiceLavorazioneSelezionata]
        )
        this.snackBar.open($localize`Cambio stato eseguito`, null, {
          duration: 2000,
        })
      })
      .catch((error) => {
        lavorazione.stato = oldStato
        this.snackBar.open(
          $localize`Cambio stato Fallito. Codice Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'Non è stato possibile promuovere lo stato della segnalazione ' +
            this.segnalazione.id +
            ' come letta da odv'
        )
      })
  }

  prossimoStato(lavorazione) {
    return prossimoStatoLavorazione(lavorazione)
  }

  iconaTransizione(lavorazione) {
    return iconaTransizione(lavorazione)
  }

  openChat(lavorazione: Lavorazione) {
    if (!lavorazione.segnalazione) {
      lavorazione.segnalazione = this.segnalazione
    }
    apriChatConSegnalanteOGestore(
      lavorazione,
      this.chatService,
      this.authService,
      this.router,
      this.snackBar,
      this.sessionData
    )
  }

  aggiornaDirittiLavorazione(
    dirittiLavorazione: DirittiLavorazione,
    modificaLavorazione: boolean,
    lavorazione: Lavorazione
  ) {
    this.lavorazioneService
      .aggiornaDirittiLavorazione(
        dirittiLavorazione ? dirittiLavorazione.id : -1,
        modificaLavorazione ? 1 : 0,
        lavorazione.id
      )
      .then((res) => {
        if (res.esito === ESITO_OK) {
          const dirittilavoraz = JSON.parse(res.payload) as DirittiLavorazione
          dirittilavoraz.lavorazione = undefined
          //  individua lavorazione da aggiornare
          const lavo = this.lavorazioni.find(
            (lav) =>
              lav.dirittiOdv.some(
                (diritto) => +diritto.id === +dirittilavoraz.id
              ) === true
          )
          if (lavo) {
            const indice = lavo.dirittiOdv.findIndex(
              (diritto) => +diritto.id === +dirittilavoraz.id
            )
            if (indice !== -1) {
              lavo.dirittiOdv.splice(indice, 1, dirittilavoraz)
            }
            this.snackBar.open(
              $localize`Diritto Lavorazione Aggiornato`,
              null,
              {
                duration: 2000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`Diritto Lavorazione Non presente in locale`,
              null,
              {
                duration: 2000,
              }
            )
          }
        } else {
          this.snackBar.open(
            $localize`Salvataggio Diritto Lavorazione fallito. Errore: ` +
              JSON.stringify(res.payload),
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Salvataggio Diritto Lavorazione fallito. Codice Errore: ` +
            error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'Non è stato possibile salvare il Diritto Lavorazione ' +
            dirittiLavorazione.id
        )
      })
  }

  puoRimuovereCondivisione(destinatario: DirittiLavorazione) {
    if (
      destinatario &&
      destinatario.odvCheHaCondiviso &&
      isMembroDiGruppo(
        this.authService.getUser(),
        destinatario.odvCheHaCondiviso
      )
    ) {
      return true
    }
    return false
  }

  puoModificareDirittiLavorazione(lavorazione: Lavorazione) {
    const result = lavorazione.dirittiOdv.some(
      (diritto) =>
        (diritto.odvCheHaCondiviso &&
          isMembroDiGruppo(
            this.authService.getUser(),
            diritto.odvCheHaCondiviso
          )) ||
        isMembroDiGruppo(this.authService.getUser(), diritto.odv)
    )
    return result
  }

  puoAprireLavorazioni() {
    const result = puoAprireLavorazioni(
      this.segnalazione,
      this.authService.getUser()
    )
    return result
  }

  possoGestireAutorizzazioneModificaLavorazione(
    dirittiLavorazione: DirittiLavorazione
  ) {
    const result =
      isMembroDiGruppo(
        this.authService.getUser(),
        dirittiLavorazione.odvCheHaCondiviso
      ) &&
      this.sessionData.configurazione.modificabilitaLavorazioniDaParteDiTerzi
    return result
  }

  possoGestireCondivisioneLavorazione(
    lavorazione: Lavorazione,
    dirittiLavorazione: DirittiLavorazione
  ) {
    const result =
      this.miaLavorazione(lavorazione) && !this.sonoIo(dirittiLavorazione.odv)
    return result
  }

  possoGestireAutorizzazioneComunicazioneConSegnalante(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const result =
      (this.hoInoltratoSegnalazione(dirittiSegnalazione) ||
        this.sonoGestoreSceltoDaSegnalante()) &&
      !dirittiSegnalazione.sceltoDaSegnalante &&
      puoChattareConSegnalante(this.authService.getUser(), this.segnalazione)
    return result
  }

  sonoAbilitatoAllaGestioneIdentitaSegnalante() {
    return (
      this.dirittiSegnalazione.find(
        (dir) =>
          isMembroDiGruppo(this.authService.getUser(), dir.odvDestinatario) &&
          dir.puoVedereSegnalante
      ) !== undefined
    )
  }

  disinoltraLavorazione(destinatario: DirittiLavorazione) {
    // console.log('all\'inizio del disinoltro il numero di lavorazioni nell\'array è', this.lavorazioni?.length);

    let indice = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
      (diritto) => diritto.id === destinatario.id
    )
    if (indice === -1) {
      indice = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
        (diritto) => diritto.id === destinatario.id
      )
    }

    if (indice === -1) {
      this.snackBar.open($localize`Destinatario non presente.`, null, {
        duration: 4000,
      })
    } else {
    }
  }

  add(event: MatChipInputEvent, lavorazione: Lavorazione): void {
    const input: MatChipInput = event.chipInput
    const value = input.inputElement.value

    // Add our action
    if ((value || '').trim()) {
      const tmpAzione = new AzioniFollowUp()
      tmpAzione.azione = value.trim()
      this.addAzione(tmpAzione, lavorazione)
    }

    // marca il form come modificato
    this.formNoteSegnalazione.controls.azioniFollowUpForm.markAsDirty()

    // Reset the input value
    if (input) {
      input.inputElement.value = ''
    }
  }

  remove(azione: AzioniFollowUp, lavorazione?: Lavorazione): void {
    const index = lavorazione.azioniFollowUp.indexOf(azione)

    if (index >= 0) {
      lavorazione.azioniFollowUp.splice(index, 1)
      this.azioniFollowUpFiltrate.push(azione)
      this.formNoteSegnalazione.controls.azioniFollowUpForm.markAsDirty()
    }
  }

  selected(azioni: AzioniFollowUp[], lavorazione: Lavorazione) {
    lavorazione.azioniFollowUp = azioni
    this.formNoteSegnalazione.controls.azioniFollowUpForm.setValue(azioni)
  }

  selectedSorgente(sorgente: SorgenteSegnalazione, lavorazione: Lavorazione) {
    lavorazione.lavorazioneMaster.sorgente = sorgente
    this.formNoteSegnalazione.controls.sorgente.setValue(sorgente)
  }

  selectedTema(tema: Tema, lavorazione: Lavorazione) {
    lavorazione.lavorazioneMaster.tema = tema
    this.formNoteSegnalazione.controls.tema.setValue(tema)
    console.log(
      'form control value',
      this.formNoteSegnalazione.controls.tema.value
    )
  }

  selectedDestinatario(destinatario: Odv) {
    if (
      this.segnalazione.dirittiSegnalazioneOdv &&
      this.segnalazione.dirittiSegnalazioneOdv.find(
        (tmpdestinatario) =>
          tmpdestinatario.odvDestinatario.nomeOdv === destinatario.nomeOdv
      )
    ) {
      // console.log('destinatario già incluso');
    } else {
      if (!this.segnalazione.dirittiSegnalazioneOdv) {
        this.segnalazione.dirittiSegnalazioneOdv =
          new Array<DirittiSegnalazione>()
      }
      const dirittiSegnalazione = new DirittiSegnalazione()
      dirittiSegnalazione.aperturaLavorazioni = false
      dirittiSegnalazione.comunicazioneConSegnalante = false
      dirittiSegnalazione.consultazioneSegnalazione = true
      dirittiSegnalazione.odvCheHaInoltrato = gruppoOdvCoinvolto(
        this.segnalazione,
        this.authService.getUser()
      )
      dirittiSegnalazione.odvDestinatario = destinatario
      dirittiSegnalazione.sceltoDaSegnalante = false
      this.segnalazione.dirittiSegnalazioneOdv.push(dirittiSegnalazione)
      // this.segnalazioneForm.get('destinatari').setValue(this.segnalazione.dirittiSegnalazioneOdv);
    }
    this.recuperaTuttiIDestinatari()
    // this.inputDestinatari.nativeElement.blur();
  }

  removeDestinatario(destinatario: Odv): void {
    const index = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
      (tmpdestinatario) =>
        tmpdestinatario.odvDestinatario.nomeOdv === destinatario.nomeOdv
    )
    if (index >= 0) {
      this.segnalazione.dirittiSegnalazioneOdv.splice(index, 1)
    }
  }

  addDestinatario(event: MatChipInputEvent): void {
    const input: MatChipInput = event.chipInput
    const value = input.inputElement.value

    if (this.destinatariSegnalazioneFiltrati.length === 1) {
      this.selectedDestinatario(this.destinatariSegnalazioneFiltrati.pop())
    }

    // Reset the input value
    if (input) {
      input.inputElement.value = ''
      this.recuperaTuttiIDestinatari()
    }
  }

  addAzione(tmpAzione, lavorazione: Lavorazione) {
    let duplicato
    if (lavorazione.azioniFollowUp) {
      duplicato = lavorazione.azioniFollowUp.find(
        (azione) => azione.azione === tmpAzione.azione
      )
    } else {
      const tmp = new Array<AzioniFollowUp>()
      lavorazione.azioniFollowUp = tmp
    }

    if (!duplicato) {
      lavorazione.azioniFollowUp.push(tmpAzione)
    } else {
      // console.log('azione già presente!!!!!');
    }
    this.formNoteSegnalazione.controls.azioniFollowUpForm.reset()
    this.formNoteSegnalazione.controls.azioniFollowUpForm.updateValueAndValidity()
    // console.log('-4-');
    this.recuperaTutteLeAzioniFollowUp()
  }

  aggiornaDocumentoLavorazione(lavorazione: Lavorazione) {
    // console.log('debbo aggiornare il documento: ' + documento.id);
  }

  aggiungiDocumentoLavorazione(documento: Documento, lavorazione: Lavorazione) {
    // console.log('debbo aggiungere il documento: ' + documento.id);
    if (!lavorazione.documenti) {
      lavorazione.documenti = []
    }

    if (lavorazione.id != null) {
      this.documentiService
        .collegaDocumentoALavorazione(documento, lavorazione)
        .then((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            if (lavorazione.documenti === undefined) {
              lavorazione.documenti = []
            }
            lavorazione.documenti?.push(JSON.parse(esito.payload))
            this.elencoAllegatiGestore.documenti = lavorazione.documenti
            this.elencoAllegatiGestore.refresh()
          } else {
            this.snackBar.open(
              $localize`Il salvataggio della nota è fallito! ` +
                esito.descrizioneEsito,
              null,
              {
                duration: 6000,
              }
            )
          }
        })
        .catch((errore) => {
          this.snackBar.open(
            $localize`Il salvataggio della nota è fallito! ` + errore,
            null,
            {
              duration: 6000,
            }
          )
        })
    } else {
      this.segnalazioniService.notificaPerDocumentoAggiunto.next({
        canale: 'documento-aggiunto-lavorazione-' + lavorazione.id,
        documento: documento,
      })
    }
  }

  collegaDocumentoALavorazione(documento: Documento, lavorazione: Lavorazione) {
    this.documentiService
      .collegaDocumentoALavorazione(documento, lavorazione)
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          if (this.elencoAllegatiGestore.documenti === undefined) {
            console.warn(
              'this.elencoAllegatiGestore.documenti è undefined e non doveva esserlo, collegaDocumentoALavorazione(documento,lavorazione)',
              documento.id,
              lavorazione.id
            )
          } else {
            this.elencoAllegatiGestore.documenti.push(
              JSON.parse(esito.payload)
            )
            this.elencoAllegatiGestore.refresh()
          }
        } else {
          this.snackBar.open(
            $localize`Il salvataggio del documento è fallito! ` +
              esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch(() => {
        // console.log(errore);
        this.snackBar.open(
          $localize`Il salvataggio del documento è fallito!`,
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  toggleLavorazioneModificabile(
    event,
    dirittoLavorazione: DirittiLavorazione,
    lavorazione: Lavorazione
  ) {
    // evento.stopPropagation();
    const destinatario = dirittoLavorazione.odv
    const diritto = lavorazione.dirittiOdv.find(
      (dirittoTmp) => +dirittoTmp.odv.id === +destinatario.id
    )
    // if (isMembroDiGruppo(this.authService.getUser(), diritto.odvCheHaCondiviso)) {
    // if (!this.statoLavorazioneModificabile(dirittoSegnalazione)) {
    //   this.condividiLavorazione(event, dirittoSegnalazione);
    // }
    this.aggiornaDirittiLavorazione(
      diritto,
      !this.statoLavorazioneModificabile(dirittoLavorazione, lavorazione),
      lavorazione
    )
    // } else {
    //  this.snackBar.open($localize `Utente non abilitato alla modifica delle autorizzazioni!', null, {
    //    duration: 6000,
    //  });
    // }

    console.log('toggleComunicazioneConSegnalante!!!')
  }

  scondividiLavorazione(destinatario, lavorazione: Lavorazione) {
    this.lavorazioneService
      .scondividiLavorazione(
        lavorazione.id,
        destinatario.odv.id,
        +this.sessionData.getSegnalazioneSelezionata().id
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          if (lavorazione.dirittiOdv) {
            lavorazione.dirittiOdv = lavorazione.dirittiOdv.filter(
              (tempDest) => tempDest.odv.id !== destinatario.odv.id
            )
          }
        } else {
          this.snackBar.open(
            $localize`Salvataggio stato condivisione fallito!` +
              esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Salvataggio stato condivisione fallito!` + errore,
          null,
          {
            duration: 6000,
          }
        )
      })
    // evento.stopPropagation();
  }

  condividiLavorazione(destinatario: OdvAzienda, lavorazione: Lavorazione) {
    this.lavorazioneService
      .condividiLavorazione(
        lavorazione.id,
        gruppoOdvCoinvolto(this.segnalazione, this.authService.getUser()).id,
        destinatario.odv.id,
        +this.sessionData.getSegnalazioneSelezionata().id
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          if (!lavorazione.dirittiOdv) {
            lavorazione.dirittiOdv = []
          }
          const dirittiLavoraz = JSON.parse(
            esito.payload
          ) as DirittiLavorazione
          dirittiLavoraz.lavorazione = undefined
          //  si accerta di non duplicare il diritto
          const dirIndex = lavorazione.dirittiOdv.findIndex(
            (diritto) => diritto.id === dirittiLavoraz.id
          )
          if (dirIndex !== -1) {
            lavorazione.dirittiOdv.splice(dirIndex, 1, dirittiLavoraz)
          } else {
            lavorazione.dirittiOdv.push(dirittiLavoraz)
          }

          // evento.target.classList.add('condivisione-attiva');
          // evento.stopPropagation();
        } else {
          this.snackBar.open(
            $localize`Salvataggio stato condivisione fallito!` +
              esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Salvataggio stato condivisione fallito!` + errore,
          null,
          {
            duration: 6000,
          }
        )
      })
  }
  statoCondivisione(destinatario: Odv, lavorazione: Lavorazione) {
    const stato =
      lavorazione &&
      lavorazione.dirittiOdv &&
      lavorazione.dirittiOdv.find(
        (tmpOdv) => +tmpOdv.odv.id === +destinatario.id
      ) !== undefined
    return stato
  }

  statoCondivisioneSospesa(destinatario: DirittiSegnalazione) {
    const stato = destinatario.consultazioneSegnalazioneSospesa
    return stato
  }

  statoLavorazioneModificabile(
    destinatario: DirittiLavorazione,
    lavorazione: Lavorazione
  ) {
    const stato =
      lavorazione &&
      lavorazione.dirittiOdv &&
      lavorazione.dirittiOdv.find(
        (tmpOdv) => +tmpOdv.odv.id === +destinatario.odv.id && tmpOdv.coGestore
      ) !== undefined
    return stato
  }

  nessunaLavorazioneGestita() {
    const result = !this.lavorazioni.some(
      (lavorazione) =>
        lavorazione.dirittiOdv.find(
          (diritto) =>
            diritto.gestore &&
            isMembroDiGruppo(this.authService.getUser(), diritto.odv)
        ) !== undefined
    )
    return result
  }

  dirittiSegnalazioniGestibili(): DirittiSegnalazione[] {
    if (this.segnalazione && !this.segnalazione.lavorazioni) {
      this.segnalazione.lavorazioni = this.lavorazioni
    }
    return (
      this.segnalazione &&
      this.segnalazione.dirittiSegnalazioneOdv &&
      this.segnalazione.dirittiSegnalazioneOdv.filter(
        (diritto) =>
          isMembroDiGruppo(
            this.authService.getUser(),
            diritto.odvDestinatario
          ) &&
          !isOdvGestoreSegnalazione(
            this.segnalazione,
            diritto.odvDestinatario
          ) &&
          diritto.aperturaLavorazioni
      )
    )
  }

  aggiornaLavorazione(lavorazione: Lavorazione) {
    if (this.segnalazione) {
      const indice = this.segnalazione.lavorazioni.findIndex(
        (lavoraz) => +lavoraz.id === +lavorazione.id
      )
      if (indice !== -1) {
        this.segnalazione.lavorazioni.splice(indice, 1, lavorazione)
      }
    }
  }

  sonoIo(destinatario: Odv) {
    if (
      destinatario &&
      isMembroDiGruppo(this.authService.getUser(), destinatario)
    ) {
      return true
    }
    return false
  }

  esecutoreInoltro(destinatario: DirittiSegnalazione) {
    if (
      destinatario.odvCheHaInoltrato &&
      isMembroDiGruppo(
        this.authService.getUser(),
        destinatario.odvCheHaInoltrato
      )
    ) {
      return true
    }
    return false
  }

  miaLavorazione(lavorazione: Lavorazione) {
    const gestore = gestoreLavorazione(lavorazione)
    if (!gestore) {
      return false
    }
    return isMembroDiGruppo(this.authService.getUser(), gestore)

    // return gestore.nomeOdv === nomeOdvCondivisione;
  }

  destinatarioSceltoDaSegnalante(nomeOdvCondivisione: string) {
    const result = isOdvSceltoDaSegnalanteByOdvName(
      this.segnalazione,
      nomeOdvCondivisione
    )
    return result
  }

  _convertiStatoLavorazione(indice): string {
    return indice ? statoLavorazioneLeggibile(indice) : 'Indefinito'
  }

  openPanelFollowUp(element): void {
    console.log(element)
    setTimeout(() => {
      // self.autoTriggerFollowUp.openPanel()
    }, 1)
  }

  prendiInCarico(odv?: Odv) {
    let lavorazione = new Lavorazione()

    const gruppoChePrendeInCarico = odv
      ? odv
      : gruppoOdvCoinvolto(this.segnalazione, this.authService.getUser())
    this.lavorazioneService
      .prendiInCaricoLavorazione(
        +this.segnalazione.id,
        gruppoChePrendeInCarico.id
      )
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.snackBar.open(
            $localize`La segnalazione è stata presa in carico!`,
            null,
            {
              duration: 6000,
            }
          )
          lavorazione = JSON.parse(esito.payload)

          if (lavorazione.chatConSegnalante) {
            this.messaggiStore.sottoscriviPerNuoviMessaggiChat(
              +lavorazione.chatConSegnalante.id
            )
          }

          // se la lavorazione è già presente la sostituisce
          if (this.segnalazione.lavorazioni) {
            // trova la lavorazione con lo stesso id
            const index = this.segnalazione.lavorazioni.findIndex(
              (lavor) => +lavor.id === +lavorazione.id
            )
            if (index !== -1) {
              this.segnalazione.lavorazioni.splice(index, 1, lavorazione)
            } else {
              this.segnalazione.lavorazioni.push(lavorazione)
            }
          } else {
            this.segnalazione.lavorazioni = [lavorazione]
          }
          this.recuperaLavorazioni(this.segnalazione.id)

          setTimeout(() => {
            this.doSelectTab(0)
          }, 500)

          this.messaggiStore.caricaMessaggiNonLetti()

          this.updateFormGestore(lavorazione)
        } else {
          this.snackBar.open(
            $localize`Presa in carico Fallita! ` + esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
  }

  prendiInCaricoDialog(dirittoSegnalazione?: DirittiSegnalazione) {
    openDialogGenerica(
      this.dialog,
      $localize`Presa in carico lavorazione`,
      '',
      $localize`Confermi la presa in carico?`,
      () => this.prendiInCarico(dirittoSegnalazione?.odvDestinatario),
      () => {}
    )
  }

  promuoviStatoDialog(lavorazione: Lavorazione | undefined) {
    openDialogGenerica(
      this.dialog,
      $localize`Transizione di stato`,
      '',
      $localize`Confermi la transizione allo stato ` +
        prossimoStatoLavorazione(lavorazione),
      () => this.promuoviStato(lavorazione),
      () => {}
    )
  }

  nonDiCompetenzaDialog(lavorazione: Lavorazione | undefined) {
    openDialogGenerica(
      this.dialog,
      $localize`Transizione di stato`,
      '',
      $localize`Confermi la transizione allo stato ` +
        getStringaStato(NON_COMPETENTE_INDEX.toString()),
      () => this.nonDiCompetenza(lavorazione),
      () => {}
    )
  }

  salvaDocumentoLavorazione(documento: Documento, lavorazione: Lavorazione) {
    const nuovoAllegato = +documento.id < 1
    this.lavorazioneService
      .collegaDocumentoALavorazione(documento, lavorazione)
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          const tmpDoc = JSON.parse(esito.payload)
          if (nuovoAllegato) {
            //  @ToDo non push per evitare di duplicare ma replace
            if (this.elencoAllegatiGestore.documenti === undefined) {
              console.warn(
                'this.elencoAllegatiGestore.documenti è undefined e non doveva esserlo, salvaDocumentoLavorazione(documento,lavorazione)',
                documento.id,
                lavorazione.id
              )
            } else {
              this.elencoAllegatiGestore.documenti.push(tmpDoc)
              this.elencoAllegatiGestore.refresh()
            }
          }
          this.snackBar.open($localize`Documento aggiornato`, null, {
            duration: 4000,
          })
        } else {
          this.snackBar.open(
            $localize`Il salvataggio dell\'allegato è fallito!` +
              esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((errore) => {
        console.log(errore)
        this.snackBar.open(
          $localize`Il salvataggio dell\'allegato è fallito!` + errore,
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  scollegaDocumentoLavorazione(documento: Documento, lavorazione: Lavorazione) {
    lavorazione.documenti = lavorazione.documenti.filter(
      (documentotemp) => documentotemp.nome !== documento.nome
    )
    if (this.sessionData.getSegnalazioneSelezionata().id !== undefined) {
      this.lavorazioneService
        .scollegaDocumentoDaLavorazione(documento, lavorazione)
        .then(() => {
          // console.log('eliminazione fisica del documento sul server eseguita');
          this.elencoAllegatiGestore.documenti = lavorazione.documenti
          this.elencoAllegatiGestore.refresh()
        })
        .catch((errore) => {
          console.log(errore)
          this.snackBar.open(
            $localize`Eliminazione fisica del file sul server fallita!`,
            null,
            {
              duration: 6000,
            }
          )
        })
    } else {
      this.elencoAllegatiGestore.documenti = lavorazione.documenti
      this.elencoAllegatiGestore.refresh()
    }
  }

  calcolaNomeGestore(lavorazione: Lavorazione) {
    const gestore = gestoreLavorazioneAbilitato(lavorazione)
    if (gestore) {
      return $localize`Lavorazione di ` + gestore.nomeOdv
    } else {
      return $localize`Non Gestita`
    }
  }

  openDialogCondividiLavorazione(
    event,
    destinatario: OdvAzienda,
    lavorazione: Lavorazione
  ): void {
    if (this.dialogRefDirittiInoltro === undefined) {
      this.dialogRefDirittiInoltro = this.dialog.open(
        DirittiInoltroDialogComponent,
        {
          width: '800px',
          data: {
            isLavorazione: true,
            destinatario: destinatario,
            segnalazioneAnonima: this.segnalazione.anonima,
            mancaConsenso: undefined,
            puoChattareConSegnalante: undefined,
            puoConsultareLavorazione: puoConsultareLavorazione(
              lavorazione,
              this.utente
            ),
            puoModificareLavorazione:
              puoModificareLavorazione(lavorazione, this.utente) &&
              this.sessionData.configurazione
                .modificabilitaLavorazioniDaParteDiTerzi,
            puoCreareLavorazioni: undefined,
            puoVedereSegnalante: undefined,
            puoVedereSegnalati: undefined,
            segnalazione: this.segnalazione,
            odvs: [
              lavorazione.dirittiOdv?.find((gestore) => gestore.gestore).odv,
            ],
          },
          disableClose: true,
          hasBackdrop: true,
        }
      )
      this.dialogRefDirittiInoltro.afterClosed().subscribe((result) => {
        // console.log('Inoltro al destinatario con result:' + JSON.stringify(result));
        if (result) {
          const gruppo = gruppoGestoreLavorazioneUtente(
            lavorazione,
            this.authService.getUser()
          )
          if (gruppo === undefined) {
            this.snackBar.open(
              $localize`Inoltro non possibile, l\'utente non risulta appartenente ad un gruppo gestore valido!`,
              null,
              {
                duration: 6000,
              }
            )
            this.dialogRefDirittiInoltro = undefined
            return
          }
          this.condividiLavorazione(destinatario, lavorazione)

          this.dialogRefDirittiInoltro = undefined
        }
      })
    }
  }

  openDialogConfermaScondivisioneLavorazione(
    event,
    dirittiLavorazione: DirittiLavorazione,
    lavorazione: Lavorazione
  ): void {
    if (this.dialogRefConfermaScondivisioneSegnalazione === undefined) {
      this.dialogRefConfermaScondivisioneSegnalazione = this.dialog.open(
        ConfermaScondivisioneSegnalazioneDialogComponent,
        {
          width: '800px',
          data: {
            nomeGruppoOdv: dirittiLavorazione.odv.nomeOdv,
            isLavorazione: true
          },
          disableClose: true,
          hasBackdrop: true,
        }
      )
      this.dialogRefConfermaScondivisioneSegnalazione
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.scondividiLavorazione(dirittiLavorazione, lavorazione)
          }

          this.dialogRefConfermaScondivisioneSegnalazione = undefined
        })
    }
  }

  userSelectedTab = false

  programmaticTabChange = false

  onTabSelected(event: MatTabChangeEvent) {
    if (this.programmaticTabChange) {
      // ignora l'evento se la tab non è stata selezionata dall'utente
      this.programmaticTabChange = false
      return
    }

    if (!this.formNoteSegnalazione?.dirty) {
      this.doSelectTab(event.index)
    }
  }

  private doSelectTab(index: number) {
    // se l'indice è maggiore del numero di lavorazioni, significa che sto selezionando la tab delle note
    if (index >= this.lavorazioni.length) {
      this.idLavorazioneSelezionata = undefined
      this.lavorazioneSelezionata = undefined
      this.indiceLavorazioneSelezionata = undefined
      this.tabGroup.selectedIndex = index
      return
    }
    this.indiceLavorazioneSelezionata = index
    this.idLavorazioneSelezionata =
      this.lavorazioni[this.indiceLavorazioneSelezionata] != null
        ? this.lavorazioni[this.indiceLavorazioneSelezionata].id
        : 0
    this.lavorazioneSelezionata =
      this.lavorazioni[this.indiceLavorazioneSelezionata]
    this.selezionaTabByIdLavorazioneSelezionata()
  }

  selezionaTabByIdLavorazioneSelezionata() {
    // console.log('selezionaTabByIdLavorazione - idLavorazioneSelezionata => ', this.idLavorazioneSelezionata);
    if (
      this.idLavorazioneSelezionata === undefined ||
      this.idLavorazioneSelezionata < 0
    ) {
      if (
        this.indiceLavorazioneSelezionata === undefined ||
        this.indiceLavorazioneSelezionata < 0
      ) {
        this.indiceLavorazioneSelezionata = 0
      }
      this.idLavorazioneSelezionata =
        this.lavorazioni[this.indiceLavorazioneSelezionata].id
    } else {
      const nuovoIndiceLavorazioneSelezionata = this.lavorazioni.findIndex(
        (lavorazione) => +lavorazione.id === +this.idLavorazioneSelezionata
      )
      if (nuovoIndiceLavorazioneSelezionata === -1) {
        if (this.indiceLavorazioneSelezionata > 0) {
          this.indiceLavorazioneSelezionata--
        } else {
          this.indiceLavorazioneSelezionata = 0
        }
        this.idLavorazioneSelezionata =
          this.lavorazioni[this.indiceLavorazioneSelezionata].id
      } else {
        this.indiceLavorazioneSelezionata = nuovoIndiceLavorazioneSelezionata
      }
    }

    if (this.tabGroup) {
      // this.programmaticTabChange = true
      this.tabGroup.selectedIndex = this.indiceLavorazioneSelezionata
    }
    this.selezionaTabByIndiceTab()
  }

  selezionaTabByIndiceTab() {
    if (this.indiceLavorazioneSelezionata === undefined) {
      return
    }
    if (this.lavorazioni.length > this.indiceLavorazioneSelezionata) {
      this.updateFormGestore(
        this.lavorazioni[this.indiceLavorazioneSelezionata]
      )
      this.formNoteSegnalazione.controls.azioniFollowUpForm.reset()
      this.recuperaTutteLeAzioniFollowUp()
      this.segnalazioniService.notificaPerAggiornamentoFormLavorazione.next(
        this.ngFormGestore
      )
    }
  }

  openDialogConfermaAnnullaModifiche(): void {}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ngFormGestore.dirty) {
      return confirm('Your changes are unsaved!! Do you like to exit')
    }
    return true
  }

  get segnalazione() {
    return this.sessionData.getSegnalazioneSelezionata()
  }
  gruppiAiQualiPossoInoltrare(azienda: Azienda, lavorazione: Lavorazione) {
    // verifico se la segnalazione è destinata ad aziende specifiche
    const aziendeDestinatarie = [this.segnalazione.aziendaInteressata]
    // l'azienda passata non è tra quelle destinarie della segnalazione torno un array vuoto
    if (
      aziendeDestinatarie &&
      aziendeDestinatarie.length > 0 &&
      aziendeDestinatarie.find(
        (aziendaDestinataria) =>
        aziendaDestinataria && aziendaDestinataria.id === azienda.id
      ) === undefined
    ) {
      return []
    }

    const result = azienda.odvAzienda
      .filter(
        (odvAzienda: OdvAzienda) =>
          this.segnalazione.dirittiSegnalazioneOdv?.find(
            (diritto) => odvAzienda.odv && +diritto.odvDestinatario.id === +odvAzienda.odv?.id && !diritto.odvDestinatarioInConflittoDiInteressi
          ) !== undefined
      )
      .filter(
        (gruppo) =>
          lavorazione.dirittiOdv?.find(
            (diritto) => +diritto.odv.id === gruppo.odv.id
          ) === undefined
      )
    return result
  }

  ciSonoPossibiliDestinatari(lavorazione: Lavorazione): boolean {
    return (
      this.destinatariSegnalazioneAziende?.find(
        (azienda) =>
          this.gruppiAiQualiPossoInoltrare(azienda, lavorazione).length > 0
      ) !== undefined
    )
  }

  openPanel(trigger) {
    if (!trigger.panelOpen) {
      console.log('apro...')
      trigger.openPanel()
    }
  }

  pianificaEliminazioneChanged(event) {
    // console.log(event)
    this.formNoteSegnalazione.controls.eliminareSegnalazione.setValue(
      event.checked ? 'true' : 'false'
    )
    if (event.checked) {
      this.formNoteSegnalazione.controls.dataDecisioneEliminazione.patchValue(
        new Date()
      )
    } else {
      this.formNoteSegnalazione.controls.dataDecisioneEliminazione.patchValue(
        null
      )
    }
    this.formNoteSegnalazione.markAsDirty()
    this.formNoteSegnalazione.controls.dataDecisioneEliminazione.markAsDirty()
  }

  compareAzione(a1: AzioniFollowUp, a2: AzioniFollowUp) {
    return a1 && a2 ? a1.azione === a2.azione : a1 === a2
  }

  compareSorgente(s1: SorgenteSegnalazione, s2: SorgenteSegnalazione) {
    return s1 && s2 ? s1.nome === s2.nome : s1 === s2
  }

  compareTema(t1: Tema, t2: Tema) {
    return t1 && t2 ? t1.tema === t2.tema : t1 === t2
  }

}
