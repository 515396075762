<div [ngClass]="screenWidth>=900 ? 'flex-row' : 'flex-column'" style="max-height: 64px; overflow: hidden;">
  <div [ngClass]="screenWidth>=900 ? 'flex-row' : 'flex-column'" style="max-height: 64px; overflow: hidden;">
    <div *ngIf="!segnalazione.consensoAudioPrestato">

    </div>
    <div *ngIf="(audio == undefined && newAudioBuffer == undefined && !segnalazione.hashAudio)" class="flex-row-center">
      <h3 i18n class="colore-riferimento"> Non sono presenti Registrazioni Audio </h3>
    </div>

    <div *ngIf="(audio != undefined || newAudioBuffer != undefined) && (segnalazione.consensoAudioPrestato)"
    [ngClass]="screenWidth>=900 ? 'flex-row' : 'flex-column'">
      <div class="flex-row">
        <mat-slider [min]="0" [max]="durata" [step]="0.01" [ngModel]="posizioneAudio" (input)="onSliderInput($event)"
          style="flex: 1 1 100%">
        </mat-slider>
        <div class="flex-column">
          <!-- formatta ore minuti e secondi in modo che abbiano due digit -->
          <h3> {{minuti(posizioneAudio) | number: '2.0-0'}}:{{secondi(posizioneAudio) | number: '2.0-0'}} </h3>
        </div>
      </div>
      <div class="flex-row">
        <button i18n mat-button class="audio-button" *ngIf="paused" (click)="playAudio()"
        style="flex: 0 1 200px">Riproduci Audio</button>
      <button i18n mat-button class="audio-button" *ngIf="!paused" (click)="pauseAudio()" style="flex: 0 1 200px">Ferma
        l'Audio</button>
      <button i18n mat-button class="audio-button" *ngIf="!readOnly" (click)="scartaAudio()"
        style="flex: 0 1 200px">Scarta Audio</button>
      </div>

    </div>
    <div *ngIf="!segnalazione.consensoAudioPrestato && segnalazione.hashAudio" style="text-align: center; flex:1;">
      <h3 style="width:100%" i18n class="colore-riferimento"> Ascolto dell'audio disabilitato su richiesta del segnalante </h3>
    </div>
    <!-- Mostra un bottone per salvare il file audio ma solo se newAudioBuffer è diverso da undefined -->
    <div *ngIf="false && (newAudioBuffer != undefined && !readOnly)" class="flex-row-center"
    style="max-height: 64px; overflow: hidden;">
      <button i18n mat-button (click)="salvaAudio()">Salva Audio</button>
    </div>

  </div>
  <div class="colonna-2" *ngIf="!readOnly" style="max-height: 64px; overflow: hidden;">
    <div *ngIf="audioAvviato" class="riga-colonna-2" style="max-height: 64px; overflow: hidden;">
      <button i18n *ngIf="!registrazioneInCorso && segnalazione.dataConsensoAudio && segnalazione.consensoAudioPrestato" mat-icon-button (click)="startRecording()">
        <mat-icon class="icone-testata-tabelle" color="warn" i18n-matTooltip matTooltip="Avvia Registrazione"
          aria-label="rimuovi segnalato">fiber_manual_record
        </mat-icon>
        Avvia Registrazione
      </button>

      <div *ngIf="registrazioneInCorso" class="registrazione-in-corso">
        <!-- MOstra ore, minuti e secondi trascorsi dall'inizio della registrazione -->
        <div>
          <!-- formatta ore minuti e secondi in modo che abbiano due digit -->
          <h3> {{minutes | number: '2.0-0'}}:{{seconds | number: '2.0-0'}} </h3>
        </div>
        <div >
          <button i18n mat-icon-button (click)="stopRecording()">
            <mat-icon class="icone-testata-tabelle" color="primary" i18n-matTooltip matTooltip="Ferma Registrazione"
              aria-label="rimuovi segnalato">stop
            </mat-icon>
            Ferma Registrazione
          </button>
        </div>
      </div>

    </div>
    <div *ngIf="!audioAvviato" class="riga-colonna-2" style="max-height: 64px; overflow: hidden;">
      <button i18n mat-button (click)="richiestaAvvioComponenteAudio()">
        Abilita Microfono
      </button>
      <svg class="speaker" xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 75 75">
        <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z"
              class="color-fill color-stroke"
              style="stroke-width:5;stroke-linejoin:round;"
        />
        <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6"
              class="color-stroke"
              style="fill:none;stroke-width:5;stroke-linecap:round"
        />
    </svg>

    </div>
  </div>
  <div *ngIf="segnalazione.dataConsensoAudio && segnalazione.consensoAudioPrestato" class="riga-colonna-2"
  style="max-height: 64px; overflow: hidden;">
    <div class="colonna-2" >
      <h3 i18n class="colore-riferimento">
        Consenso prestato in data {{segnalazione.dataConsensoAudio | date: 'dd/MM/yyyy HH:mm:ss'}}
      </h3>
      <button *ngIf="!readOnly" i18n mat-button (click)="confermaRitiroConsenso()">
        Ritira il consenso
      </button>
    </div>
    <button *ngIf="readOnly && odvDestinatario && audio != undefined && segnalazione.consensoAudioPrestato" i18n mat-button (click)="confermaDisabilitazioneAudio()">
      Disabilita ascolto dell'audio
    </button>
  </div>
  <!--div *ngIf="!segnalazione.consensoAudioPrestato && segnalazione.hashAudio" class="flex-row-center">
    <button *ngIf="readOnly && odv" i18n mat-button (click)="confermaRiabilitazioneAudio()">
      Riabilita ascolto dell'audio
    </button>
  </div-->
</div>
