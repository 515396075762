import { Contenuto } from './contenuto'

export class VersioneContenuto {
  constructor(documento: string, nome: string, tipo: string) {
/*         if (!this.segnalazioni) {
          this.segnalazioni = new Array<Segnalazione>();
      }
      this.segnalazioni.push(segnalazione); */
      this.nome = nome
      this.tipo = tipo
      this.base64 = documento
  }

  id: string

  nome: string

  tipo: string

  lingua: string // ad esempio: "it", "en", "fr", ecc.

  testo: string

  oggetto: string // opzionale, per le email

  url: string // opzionale, per i file
  usaFile: boolean // true se usa il file, false se usa il testo

  contenuto: Contenuto

  size: number

  base64: string | ArrayBuffer | null
}
