import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../../servizi/auth.service'
import { SegnalazioneService } from '../../servizi/segnalazione.service'
import { SessionData } from '../../sessione/dati-sessione'
import { LanguageService } from '../../servizi/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {

  @ViewChild('languageSelector', { static: false }) languageSelector: ElementRef

  selectedLanguage: string

  languages = []
  showLanguages: any
  shiftAmount = 0
  constructor(private router: Router, private sessionData: SessionData,
    private segnalazioneService: SegnalazioneService,
    private authService: AuthService,
    private languageService: LanguageService) { }

  ngOnInit(): void {
    // recupero la lingua corrente
    this.selectedLanguage = window.location.href.split('/')[3]
    // cicla sulle lingue e crea un array di oggetti con codice e nome
    this.lingue().forEach((lang) => {
      this.languages.push({
        code: lang,
        name: lang,
        flag: `/assets/img/flags/${lang}.svg`,
      })
    })
  }

  dropdownVisible = false

  toggleLanguages() {
    this.showLanguages = !this.showLanguages

    if (this.showLanguages) {
      const element = this.languageSelector.nativeElement
      const distanceToLeft = element.offsetLeft
      const distanceToRight = window.innerWidth - (element.offsetLeft + element.offsetWidth)

      this.shiftAmount = distanceToRight < distanceToLeft ? this.languages.length * 28 : 0
    } else {
      this.shiftAmount = 0
    }

  }

  languageSelected(language: any) {
    this.selectedLanguage = language
    this.languageService.setLanguage(language.code);
    // Ottieni l'intero URL
    const fullUrl = window.location.href;

    // Dividi l'URL in base a "#" per separare la base dell'URL dalla route interna di Angular
    const [baseUrl, hashUrl] = fullUrl.split('#');

    // Controlla se esiste una parte hash e procedi con la sostituzione della lingua
    if (hashUrl) {
      // Costruisci il nuovo hash URL sostituendo il segmento della lingua
      const newBaseUrl = baseUrl.replace(/\/[a-z]{2}\//, `/${language.code}/`);

      // Combina la base dell'URL con il nuovo hash URL
      let newUrl = `${newBaseUrl}#${hashUrl}`;

      // recupero la segnalazione selezionata
      const segnalazione = this.sessionData.getSegnalazioneSelezionata()

      // recupero l'utente loggato
      const isUtenteSegnalante = this.authService.getUser() &&
        this.authService.getUser()?.automatico

      // eseguo il prossimo blocco di codice solo se non c'è una segnalazione selezionata oppure ha id nullo o indefinito
      if (isUtenteSegnalante && (segnalazione === null || segnalazione === undefined ||
        segnalazione?.id === null || segnalazione?.id === undefined)) {
        this.segnalazioneService.salvaSegnalazioneInLocalStorage.next()
        let segnalazioneSalvata = false
        let audioSalvato = false
        let segnalatiSalvati = false
        let baseTime = new Date().getTime()
        while ((!segnalazioneSalvata || !audioSalvato || !segnalatiSalvati) && new Date().getTime() - baseTime < 2000) {
          segnalazioneSalvata = localStorage.getItem('segnalazione') !== null
          audioSalvato = localStorage.getItem('audio') !== null
          segnalatiSalvati = localStorage.getItem('segnalati') !== null
        }
      }

      // Redirigi all'URL modificato
      window.location.href = newUrl;

    }
  }

  get coloreSfondo() {
    const colore = this.sessionData.colorCombo.coloreBackgroundToolbar
    return colore
  }

  get coloreTesto() {
    const colore = this.sessionData.colorCombo.coloreBordoSelettoreLingua
    return colore
  }

  get sonoPronto() {
    return this.sessionData.colorCombo !== undefined
  }

  lingue() {
    return this.sessionData.configurazione?.lingue === undefined ||
      this.sessionData.configurazione?.lingue === null ||
      this.sessionData.configurazione?.lingue?.trim() === ''
      ? []
      : this.sessionData.configurazione?.lingue.split(',')
  }

  get doubleBaloon() {
    return '/assets/img/double-baloon.svg'
  }

}
