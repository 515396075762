/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Documento } from '../modello/documento'
import { Messaggio } from '../modello/messaggio'
import { Lavorazione } from '../modello/lavorazione'
import { ESITO_OK } from './esito'
import { SessionData } from '../sessione/dati-sessione'
import { ChatService } from '../servizi/chat.service'
import { AuthService } from '../servizi/auth.service'
import { Router } from '@angular/router'
import { Chat } from '../modello/chat'
import { MatSnackBar } from '@angular/material/snack-bar'

import { DirittiSegnalazione } from '../modello/diritti-segnalazione'
import { Consenso } from '../modello/consenso'
import { VERSIONE } from './version'
import { ConfermaDecisioneDialogComponent } from '../elenco-segnalazioni/consensi-segnalante/conferma-decisione.dialog.component'
import { Faq } from '../modello/domanda'
import { PDFDocument } from 'pdf-lib'

import { convertiInPdf } from './helper-segnalazioni'
import EXIF from 'exif-js'
import * as UPNG from 'upng-js'
import { VersioneContenuto } from '../modello/versione-contenuto'

export function getVersione() {
  return 'Versione ' + VERSIONE
}

// `a.click()` doesn't work for all browsers (#465)
function click(node, target?) {
  try {
    node.dispatchEvent(new MouseEvent('click'))
  } catch (e) {
    const evt = document.createEvent('MouseEvents')
    evt.initMouseEvent(
      'click',
      true,
      true,
      window,
      0,
      0,
      0,
      80,
      20,
      false,
      false,
      false,
      false,
      0,
      null
    )
    node.dispatchEvent(evt)
  }
}

function corsEnabled(url) {
  const xhr = new XMLHttpRequest()
  // use sync to avoid popup blocker
  xhr.open('HEAD', url, false)
  xhr.send()
  return xhr.status >= 200 && xhr.status <= 299
}

const _global = (function () {
  // some use content security policy to disable eval
  try {
    // eslint-disable-next-line no-new-func
    return (
      Function('return this')() ||
      // eslint-disable-next-line no-eval
      (42, eval)('this')
    )
  } catch (e) {
    // every global should have circular reference
    // used for checking if someone writes var window = {}; var self = {}
    return typeof window === 'object' && window.window === window
      ? window
      : typeof self === 'object' && self.self === self
        ? self
        : typeof global === 'object' && global.global === global
          ? global
          : this
  }
})()

function saveAs(blob, name, opts) {
  // console.log('dentro!!!!');
  const URL = _global.URL || _global.webkitURL
  const a = document.createElement('a')
  name = name || blob.name || 'download'

  a.download = name
  a.rel = 'noopener' // tabnabbing

  // TODO: detect chrome extensions & packaged apps
  // a.target = '_blank'

  if (typeof blob === 'string') {
    // Support regular links
    a.href = blob
    if (a.origin !== location.origin) {
      corsEnabled(a.href)
        ? download(blob, name, opts)
        : click(a, (a.target = '_blank'))
    } else {
      click(a)
    }
  } else {
    // Support blobs
    a.href = URL.createObjectURL(blob)
    setTimeout(function () {
      URL.revokeObjectURL(a.href)
    }, 4e4) // 40s
    setTimeout(function () {
      click(a)
    }, 0)
  }
}

function bufferToBase64(buffer: ArrayBuffer, mimeType: string): string {
  const bytes = new Uint8Array(buffer)
  let binary = ''
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return `data:${mimeType};base64,` + window.btoa(binary)
}

export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function b64ToBlob(b64Data, contentType?, sliceSize?: 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export function compare(a, b, isAsc) {
  // Check for null or undefined values
  if (a === null || a === undefined) {
    return isAsc ? -1 : 1
  }
  if (b === null || b === undefined) {
    return isAsc ? 1 : -1
  }

  // Check if both are date objects
  if (a instanceof Date && b instanceof Date) {
    return (a.getTime() < b.getTime() ? -1 : 1) * (isAsc ? 1 : -1)
  }

  // Check if both are strings
  if (typeof a === 'string' && typeof b === 'string') {
    return (
      a.localeCompare(b, undefined, { sensitivity: 'base' }) * (isAsc ? 1 : -1)
    )
  }

  // For numbers and others
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}

export function compareNumbers(a: number, b: number, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}

export function compareDates(a: any, b: any, isAsc: boolean): number {
  if (a instanceof Date && b instanceof Date) {
    return (a.getTime() < b.getTime() ? -1 : 1) * (isAsc ? 1 : -1)
  }
  // Gestisci il caso in cui a o b non siano oggetti Date
  // Puoi ritornare 0 o gestirlo in un altro modo, a seconda delle tue esigenze
  return 0
}


export class ServiziRest {
  static urlCrossOrigin = ''

  static urlRegister =
    window.location.origin + '/' + 'public/api/v1/registration'
  static urlLogin = window.location.origin + '/' + 'public/api/v1/login'
  static urlLoginConPin =
    window.location.origin + '/' + 'public/api/v1/loginConPin'
  static urlLoginNuovaSegnalazione =
    window.location.origin + '/' + 'public/api/v1/loginNuovaSegnalazione'
  static urlRinnovaToken =
    window.location.origin + '/' + 'api/v1/rinnovaToken'
  static urlResetPin =
    window.location.origin + '/' + 'public/api/v1/resetPin'
  static urlVerifyOtp =
    window.location.origin + '/' + 'public/api/v1/verifyOtp'
  static urlRenewMfa =
    window.location.origin + '/' + 'public/api/v1/getMfaQrCode'
  static urlResetPinNoCaptcha =
    window.location.origin + '/' + 'public/api/v1/resetPinNoCaptcha'
  static urlGeneraPin =
    window.location.origin + '/' + 'public/api/v1/generaNuovoPin'
  static urlGetInformativa =
    window.location.origin + '/' + 'public/api/v1/aziende/nome/'
  static urlLogout = window.location.origin + '/' + 'api/v1/logout'

  static urlInitiateSSO = window.location.origin + '/' + 'public/api/v1/initiateSSO'
  static urlNuovaSegnalazione =
    window.location.origin + '/' + 'api/v1/segnalazioni'
  static urlMessaggi = window.location.origin + '/' + 'api/v1/messaggi'
  static urlGetSegnalazione =
    window.location.origin + '/' + 'api/v1/segnalazioni'
  static urlGetSegnalazioneById =
    window.location.origin + '/' + 'api/v1/segnalazioniById'
  static urlInoltraSegnalazione =
    window.location.origin + '/' + 'api/v1/segnalazioni/inoltra'
  static urlDisinoltraSegnalazione =
    window.location.origin + '/' + 'api/v1/segnalazioni/disinoltra'
  static urlRipristinaInoltro =
    window.location.origin + '/' + 'api/v1/segnalazioni/ripristinaInoltro'
  static urlSetVisualizzataSegnalazione =
    window.location.origin + '/' + 'api/v1/segnalazioni/messaggiLetti'

  static urlDirittiSegnalazione =
    window.location.origin + '/' + 'api/v1/dirittiSegnalazione'

  static urlGetSegnalazioniByAzioneFollowUp =
    window.location.origin + '/' + 'api/v1/segnalazioni/azioniFollowUp'
  static urlGetSegnalazioniByTema =
    window.location.origin + '/' + 'api/v1/segnalazioni/tema'
  static urlGetSegnalazioniByProvenienza =
    window.location.origin + '/' + 'api/v1/segnalazioni/provenienza'
  static urlGetSegnalazioniByAmmissibilita =
    window.location.origin + '/' + 'api/v1/segnalazioni/ammissibilita'
  static urlGetSegnalazioniByMotivoInammissibilita =
    window.location.origin + '/' +
    'api/v1/segnalazioni/motivoInammissibilita'
  static urlGetSegnalazioniByDirittiSegnalazione =
    window.location.origin + '/' +
    'api/v1/segnalazioni/byDirittiSegnalazione'
  static urlGetDirittiSegnalazione =
    window.location.origin + '/' + 'api/v1/segnalazioni/dirittiSegnalazione'
  static urlGetOmissisByDirittiSegnalazione =
    window.location.origin + '/' + 'api/v1/omissis/byDirittiSegnalazione'

  static urlOscuramenti =
    window.location.origin + '/' + 'api/v1/oscuramenti'

  //  recupera le segnalazioni di pertinenza dell'utente corrente
  static urlGetSegnalazioni =
    window.location.origin + '/' + 'api/v1/segnalazioni'
  static urlStatisticheProvenienza =
    window.location.origin + '/' + 'api/v1/statistiche/provenienza'
  static urlStatisticheTema =
    window.location.origin + '/' + 'api/v1/statistiche/tema'
  static urlStatisticheAmmissibilita =
    window.location.origin + '/' + 'api/v1/statistiche/ammissibilita'
  static urlStatisticheMotiviInammissibilita =
    window.location.origin + '/' + 'api/v1/statistiche/motiviInammissibilita'
  static urlStatisticheAzioniFollowUp =
    window.location.origin + '/' + 'api/v1/statistiche/azioniFollowUp'

  static urlGetApprofondimenti =
    window.location.origin + '/' + '/getApprofondimenti'
  static urlGetMessaggiNonLetti =
    window.location.origin + '/' + 'api/v1/messaggi/non-letti'
  static urlAggiungiApprofondimento =
    window.location.origin + '/' + '/aggiungiApprofondimento'
  static urlDestinatari = window.location.origin + '/' + 'api/v1/odv'
  static urlDestinatariAperturaSegnalazione =
    window.location.origin + '/' + 'api/v1/odv/aperturaSegnalazione'
  static urlDestinatariByNome =
    window.location.origin + '/' + 'api/v1/odv/nomi'
  static urlSbloccaSegnalante =
    window.location.origin + '/' + 'api/v1/segnalazioni/sbloccaSegnalante'
  static urlRichiestaSbloccoSegnalante =
    window.location.origin + '/' +
    'api/v1/segnalazioni/richiestaSbloccoSegnalante'
  static urlNumeroRichiestaSbloccoSegnalanteDaLavorare =
    window.location.origin + '/' +
    'api/v1/segnalazioni/richiestaSbloccoSegnalante/numeroDaLavorare'

  static urlSbloccaSegnalato =
    window.location.origin + '/' + 'api/v1/segnalazioni/sbloccaSegnalato'

  static urlDocumenti = window.location.origin + '/' + 'api/v1/documenti'
  static urlDocumentiBypassAuth =
    window.location.origin + '/' + 'public/api/v1/documenti'
  static urlScollegaDocumentoDaSegnalazione =
    window.location.origin + '/' + 'api/v1/documenti/scollegaSegnalazione'
  static urlScollegaDocumentoDaInformativa =
    window.location.origin + '/' + 'api/v1/documenti/scollegaInformativa'
  static urlCollegaDocumentoAInformativa =
    window.location.origin + '/' + 'api/v1/documenti/collegaInformativa'
  static urlCreaOAggiornaCollegamentoTraDocumentoESegnalazione =
    window.location.origin + '/' + 'api/v1/documenti/collegaSegnalazione'

  // static urlCreaOAggiornaCollegamentoTraDocumentoENotaGestore = window.location.origin + '/'  + 'api/v1/documenti/collegaNotaGestore';
  // static urlScollegaDocumentoDaNotaGestore = window.location.origin + '/'  + 'api/v1/documenti/scollegaNotaGestore';

  static urlCreaOAggiornaCollegamentoTraDocumentoENotaSegnalazione =
    window.location.origin + '/' + 'api/v1/documenti/collegaNotaSegnalazione'
  static urlScollegaDocumentoDaNotaSegnalazione =
    window.location.origin + '/' +
    'api/v1/documenti/scollegaNotaSegnalazione'

  static urlCreaOAggiornaCollegamentoTraDocumentoELavorazione =
    window.location.origin + '/' + 'api/v1/documenti/collegaLavorazione'
  static urlScollegaDocumentoDaLavorazione =
    window.location.origin + '/' + 'api/v1/documenti/scollegaLavorazione'

  static urlCreaOAggiornaCollegamentoTraDocumentoEMessaggio =
    window.location.origin + '/' + 'api/v1/documenti/collegaMessaggio'
  static urlScollegaDocumentoDaMessaggio =
    window.location.origin + '/' + 'api/v1/documenti/scollegaMessaggio'

  static urlNuovoDocumento =
    window.location.origin + '/' + 'api/v1/documenti'
  static urlDownloadDocumentoDaRepository =
    window.location.origin + '/' + 'api/v1/documenti/repo'
  static urlDocumentiAssociatiASegnalazione =
    window.location.origin + '/' + 'api/v1/documenti/segnalazione'

  static urlUtenti = window.location.origin + '/' + 'api/v1/utenti'
  static urlRiattivaUtente =
    window.location.origin + '/' + 'api/v1/utenti/riattiva'
  static urlAziende = window.location.origin + '/' + 'api/v1/aziende'

  static urlSorgentiSegnalazione =
    window.location.origin + '/' + 'api/v1/sorgente'
  static urlGetSorgenteByNome =
    window.location.origin + '/' + 'api/v1/sorgente/nome'
  static urlGetSorgentiByNome =
    window.location.origin + '/' + 'api/v1/sorgente/nomi'

  static urlGestoriSegnalazione = window.location.origin + '/' + 'api/v1/odv'
  static urlGetGestoreByNome =
    window.location.origin + '/' + 'api/v1/odv/nome'
  static urlGetGestoriByNome =
    window.location.origin + '/' + 'api/v1/odvs/nomi'

  static urlTemi = window.location.origin + '/' + 'api/v1/tema'
  static urlGetTemaByNome = window.location.origin + '/' + 'api/v1/tema/nome'
  static urlGetTemiByNome = window.location.origin + '/' + 'api/v1/tema/nomi'

  static urlAzioniFollowUp =
    window.location.origin + '/' + 'api/v1/azioneFollowUp'
  static urlGetAzioneFollowUpByNome =
    window.location.origin + '/' + 'api/v1/azioneFollowUp/nome'
  static urlGetAzioniFollowUpByNome =
    window.location.origin + '/' + 'api/v1/azioneFollowUp/nomi'

  static urlChat = window.location.origin + '/' + 'api/v1/chat'
  static urlGetMessaggiByChat =
    window.location.origin + '/' + 'api/v1/chat/messaggi'
  static urlGetPartecipantiByChat =
    window.location.origin + '/' + 'api/v1/chat/partecipanti'
  static urlGetPossibiliUtentiPartecipantiByChat =
    window.location.origin + '/' + 'api/v1/chat/possibiliUtentiPartecipanti'
  static urlGetPossibiliGruppiPartecipantiByChat =
    window.location.origin + '/' + 'api/v1/chat/possibiliGruppiPartecipanti'
  static urlGetElencoIdChatUtente =
    window.location.origin + '/' + 'api/v1/chat/elencoChatId/get'
  static urlChatAddUtentePartecipante =
    window.location.origin + '/' + 'api/v1/chat/addUtentePartecipante'
  static urlChatAddGruppoPartecipante =
    window.location.origin + '/' + 'api/v1/chat/addGruppoPartecipante'
  static urlChatAddSegnalante =
    window.location.origin + '/' + 'api/v1/chat/addSegnalante'
  static urlChatRemoveUtentePartecipante =
    window.location.origin + '/' + 'api/v1/chat/removeUtentePartecipante'
  static urlChatRemoveGruppoPartecipante =
    window.location.origin + '/' + 'api/v1/chat/removeGruppoPartecipante'
  static urlChatRemoveSegnalante =
    window.location.origin + '/' + 'api/v1/chat/removeSegnalante'
  static urlChatImpostaModeratore =
    window.location.origin + '/' + 'api/v1/chat/impostaModeratore'

  static urlLavorazione = window.location.origin + '/' + 'api/v1/lavorazione'
  static urlLavorazioniByUser =
    window.location.origin + '/' + 'api/v1/lavorazioni'
  static urlIndaginiAltriGestori =
    window.location.origin + '/' + 'api/v1/lavorazione/altriGestori'
  static urlPrendiInCaricoLavorazione =
    window.location.origin + '/' +
    'api/v1/lavorazione/prendiInCaricoLavorazione'
  static urlSetStatoLavorazione =
    window.location.origin + '/' + 'api/v1/lavorazione/stato'
  static urlCondividiLavorazione =
    window.location.origin + '/' + 'api/v1/lavorazione/condividi'
  static urlScondividiLavorazione =
    window.location.origin + '/' + 'api/v1/lavorazione/scondividi'

  static urlAggiornaDirittiLavorazione =
    window.location.origin + '/' + 'api/v1/dirittiLavorazione/aggiorna'

  static urlOmissis =
    window.location.origin + '/' + 'api/v1/omissis'

  static urlGetConfigurazione =
    window.location.origin + '/' + 'public/api/v1/configurazione'
  static urlConfigurazione =
    window.location.origin + '/' + 'api/v1/configurazione'
  static urlLoadTipiNonConformita =
    window.location.origin + '/' + 'api/v1/loadTipiNonConformita'
  static urlLoadTipiNonConformitaByHashAndLanguage =
    window.location.origin + '/' +
    'api/v1/loadTipiNonConformitaByHashAndLanguage'
  static urlUpdateTipiNonConformita =
    window.location.origin + '/' + 'api/v1/updateTipiNonConformita'
  static urlRemoveTipiNonConformita =
    window.location.origin + '/' + 'api/v1/tipiNonConformita'
  static urlInformativa = window.location.origin + '/' + 'api/v1/informativa'
  static urlConsenso = window.location.origin + '/' + 'api/v1/consenso'
  static urlConsensDaAutorizzare = window.location.origin + '/' + 'api/v1/consensiDaAutorizzare'

  static urlUpdateTraduzione =
    window.location.origin + '/' + 'api/v1/traduzione'
  static urlAddTraduzione =
    window.location.origin + '/' + 'api/v1/traduzione'

  static urlAudioBySegnalazione =
    window.location.origin + '/' + 'api/v1/audio/bySegnalazione'
  static urlAudio = window.location.origin + '/' + 'api/v1/audio'
  static urlInibisciAudio =
    window.location.origin + '/' + 'api/v1/inibisciAudio'
  static urlDisinibisciAudio =
    window.location.origin + '/' + 'api/v1/disinibisciAudio'
  static urlLogOperativo =
    window.location.origin + '/' + 'api/v1/logOperativo'
  static urlAllLogOperativo =
    window.location.origin + '/' + 'api/v1/logOperativi'
  static urlDestinatariPredefinitiPerTipologiaSegnalazione =
    window.location.origin + '/' + 'api/v1/destinatariPerTipologia'
  static urlDestinatariPredefinitiPerSocieta =
    window.location.origin + '/' + 'api/v1/destinatariPerAzienda'
  static urlVideo = window.location.origin + '/' + 'api/v1/video'
  static urlVideoFile = window.location.origin + '/' + 'api/v1/videoFile'
  static urlAudioUpload =
    window.location.origin + '/' + 'api/v1/audio-upload'
  static urlAudioDownload =
    window.location.origin + '/' + 'api/v1/audio-download'

  static urlLandingPageVideoUpload =
    window.location.origin + '/' + 'api/v1/landingPageVideoUpload'

  static urlContenutoGet =
    window.location.origin + '/' + 'public/api/v1/contenuti'
  static urlContenutoProtected =
    window.location.origin + '/' + 'api/v1/contenuti'
  static urlFileDownload =
    window.location.origin + '/' + 'public/api/v1/file'
  static urlColorCombination = window.location.origin + '/' + 'api/v1/colori'
  static urlPublicColorCombination =
    window.location.origin + '/' + 'public/api/v1/colori'

  static urlUtentiInibiti =
    window.location.origin + '/' + 'api/v1/segnalazioni/utentiInibiti'

  static urlRitiraSegnalazione =
    window.location.origin + '/' + 'api/v1/segnalazioni/ritiraSegnalazione'

  static urlPianificazioneCancellazioneSegnalazione =
    window.location.origin + '/' + 'api/v1/segnalazioni/aggiornaPianificazioneCancellazione'

  static urlIdp =
    window.location.origin + '/' + 'api/v1/idp'
  static urlBehaviourData = window.location.origin + '/' + 'public/api/v1/behaviourData'
  static urlConfigurazioneBan = window.location.origin + '/' + 'api/v1/ban'
  static urlLoadClientConfigurations = window.location.origin + '/' + 'api/v1/loadClientConfigurations'
  static urlSetupNewClient = window.location.origin + '/' + 'api/v1/setupNewClient'
  static urlClienti = window.location.origin + '/' + 'api/v1/clienti'
}

export const NUOVA = 'NUOVA'
export const IN_LAVORAZIONE = 'IN_LAVORAZIONE'
export const ISTRUTTTORIA_APERTA = 'ISTRUTTORIA_APERTA'
export const CHIUSA = 'CHIUSA'
export const INAMMISSIBILE = 'INAMMISSIBILE'
export const NON_COMPETENTE = 'NON_COMPETENTE'

export const NOME_CHAT_CON_SEGNALANTE = $localize`Chat con segnalante`

export const NUOVA_INDEX = 1
export const IN_LAVORAZIONE_INDEX = 2
export const ISTRUTTTORIA_APERTA_INDEX = 3
export const CHIUSA_INDEX = 4
export const INAMMISSIBILE_INDEX = 5
export const NON_COMPETENTE_INDEX = 6

export const BOZZA = 'BOZZA'
export const SEGNALAZIONE_RICEVUTA = 'SEGNALAZIONE_RICEVUTA'
export const NESSUNA_LAVORAZIONE_IN_CORSO = 'NESSUNA_LAVORAZIONE_IN_CORSO'
export const LAVORAZIONI_IN_CORSO = 'LAVORAZIONI_IN_CORSO'
export const ISTRUTTORIA_IN_CORSO = 'ISTRUTTORIA_IN_CORSO'
export const ALCUNE_LAVORAZIONI_CHIUSE = 'ALCUNE_LAVORAZIONI_CHIUSE'
export const TUTTE_LE_LAVORAZIONI_CHIUSE = 'TUTTE_LE_LAVORAZIONI_CHIUSE'
export const RITIRATA = 'RITIRATA'

export const BOZZA_INDEX = 1
export const SEGNALAZIONE_RICEVUTA_INDEX = 8
export const NESSUNA_LAVORAZIONE_IN_CORSO_INDEX = 2
export const LAVORAZIONI_IN_CORSO_INDEX = 3
export const ISTRUTTORIA_IN_CORSO_INDEX = 7
export const ALCUNE_LAVORAZIONI_CHIUSE_INDEX = 4
export const TUTTE_LE_LAVORAZIONI_CHIUSE_INDEX = 5
export const RITIRATA_INDEX = 6

export const TIPO_FILE_INFORMATIVA = 'INFORMATIVA'
export const TIPO_FILE_ALLEGATO = 'ALLEGATO'

export const MAX_FILE_SIZE_MB = 20
export const MAX_TOTAL_FILE_SIZE_MB = 50

export enum Operazioni {
  RicevimentoSegnalazione = 'ricevimento-segnalazione',
  PresaInCarico = 'presa-in-carico',
  PresaVisioneDatiSegnalante = 'presa-visione-dati-segnalante',
  PresaVisioneDatiSegnalati = 'presa-visione-dati-segnalati',
  AperturaIstruttoria = 'apertura-istruttoria',
  RiaperturaIstruttoria = 'riapertura-istruttoria',
  RifiutoAssegnazione = 'rifiuto-assegnazione',
  CondivisioneConAltroGestore = 'condivisione-con-altro-gestore',
  TermineCondivisioneConAltroGestore = 'termine-condivisione-con-altro-gestore',
  RichiestaConsensoSegnalante = 'richiesta-consenso-segnalante',
  AnnullamentoRichiestaConsensoSegnalante = 'annullamento-richiesta-consenso-segnalante',
  RispostaSegnalanteAllaRichiestaConsenso = 'risposta-segnalante-alla-richiesta-consenso',
  ChiusuraLavorazione = 'chiusura-lavorazione',
  RiaperturaLavorazione = 'riapertura-lavorazione',
  ConsensoAudioSegnalazioneConcesso = 'consenso-audio-segnalazione-concesso',
  ConsensoAudioSegnalazioneRevocato = 'consenso-audio-segnalazione-revocato',
  ConsensoAudioChatConcesso = 'consenso-audio-chat-concesso',
  ConsensoAudioChatRevocato = 'consenso-audio-chat-revocato',
  BloccoAscoltoAudioSegnalazione = 'blocco-ascolto-audio-segnalazione',
  SbloccoAscoltoAudioSegnalazione = 'sblocco-ascolto-audio-segnalazione',
  TrascrizioneVocaliTerminata = 'trascrizione-vocali-terminata',
  AutoesclusioneUtenteGestore = 'autoesclusione-utente-gestore',
  RiammissioneUtenteGestore = 'riammissione-utente-gestore',
  ModificaDatiIdentificativiSegnalante = 'modifica-dati-identificativi-segnalante',
  RitiroSegnalazione = 'ritiro-segnalazione',
  AnnullamentoRitiroSegnalazione = 'annullamento-ritiro-segnalazione',
  PianificazioneCancellazioneSegnalazione = 'pianificazione-cancellazione-segnalazione',
  AnnullamentoPianificazioneCancellazioneSegnalazione = 'annullamento-pianificazione-cancellazione-segnalazione',

}

export enum TipoContenuto {
  DOCUMENTO = 'documento',
  EMAIL = 'email',
  // aggiungi qui altri tipi di contenuti se necessario
}

export enum SottoTipoContenuto {
  // documenti
  COOKIE_POLICY = 'cookie_policy',
  PRIVACY = 'privacy',
  MANUALE_UTENTE = 'manuale_utente',
  MANUALE_GESTORE = 'manuale_gestore',
  INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI = 'informativa_sul_trattamento_dei_dati_personali',
  PROCEDURA_WHISTLEBLOWING = 'procedura_whistleblowing',
  INFORMATIVA_PER_CREAZIONE_NUOVA_SEGNALAZIONE = 'informativa_per_creazione_nuova_segnalazione',
  MISURE_DI_SICUREZZA = 'misure_di_sicurezza',
  TERMINI_SERVIZIO = 'termini_servizio',
  NORMATIVA = 'normativa',
  LANDING_PAGE = 'landing_page',
  FAQ = 'faq',
  INFORMATIVA_REGISTRAZIONE_UTENTE = 'informativa_registrazione_utente',
  LOGO_CLIENTE = 'logo_cliente',
  LOGO_SECURE = 'logo_secure',
  // notifiche email
  BENVENUTO = 'benvenuto',
  BENVENUTO_SSO = 'benvenuto_sso',
  CAMBIO_PASSWORD = 'cambio_password',
  NUOVA_SEGNALAZIONE = 'nuova_segnalazione',
  NUOVO_MESSAGGIO = 'nuovo_messaggio',
  ATTIVAZIONE_ACCOUNT = 'attivazione_account',
  VERIFICA_CASELLA_POSTA = 'verifica_casella_posta',
  RESET_PIN = 'reset_pin',
  CAMBIO_STATO_SEGNALAZIONE = 'cambio_stato_segnalazione',
  CAMBIO_STATO_LAVORAZIONE = 'cambio_stato_lavorazione',
  INOLTRO_LAVORAZIONE = 'inoltro_lavorazione',
  INOLTRO_SEGNALAZIONE = 'inoltro_segnalazione',
  ANNULLAMENTO_INOLTRO_SEGNALAZIONE = 'annullamento_inoltro_segnalazione',
  AZIONI_IN_CARICO_AL_SEGNALANTE = 'azioni_in_carico_al_segnalante',
  RICHIESTA_SBLOCCO_IDENTITA_SEGNALANTE = 'richiesta_sblocco_identita_segnalante',
  NEGAZIONE_SBLOCCO_IDENTITA_SEGNALANTE = 'negazione_sblocco_identita_segnalante',
  CONSULTAZIONE_IDENTITA_SEGNALANTE = 'consultazione_identita_segnalante',
  CONCESSIONE_SBLOCCO_IDENTITA_SEGNALANTE = 'concessione_sblocco_identita_segnalante',
  RICHIESTA_CONSENSO_AL_SEGNALANTE = 'richiesta_consenso_al_segnalante',
  REVOCA_RICHIESTA_CONSENSO_AL_SEGNALANTE = 'revoca_richiesta_consenso_al_segnalante',
  NEGAZIONE_CONSENSO_DAL_SEGNALANTE = 'negazione_consenso_dal_segnalante',
  ACCETTAZIONE_CONSENSO_DAL_SEGNALANTE = 'accettazione_consenso_dal_segnalante',
  RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE = 'richiesta_consenso_registrazioni_audio_segnalazione',
  RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_CHAT = 'richiesta_consenso_registrazioni_audio_chat',
  REVOCA_CONSENSO_VOCALE_CHAT = 'revoca_consenso_vocale_chat',
  PROSSIMA_SCADENZA_CHIUSURA_INDAGINE = 'prossima_scadenza_chiusura_indagine',
  RAGGIUNTA_SCADENZA_CHIUSURA_INDAGINE = 'raggiunta_scadenza_chiusura_indagine',
  ATTIVAZIONE_MFA = 'attivazione_mfa',
  RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE_VOCALE = 'richiesta_consenso_registrazioni_audio_segnalazione_vocale',
}

export function mapNumberToSottoTipoContenuto(
  numero: number
): SottoTipoContenuto | null {
  switch (numero) {
    case 0:
      return SottoTipoContenuto.COOKIE_POLICY
    case 1:
      return SottoTipoContenuto.INFORMATIVA_PER_CREAZIONE_NUOVA_SEGNALAZIONE
    case 2:
      return SottoTipoContenuto.PRIVACY
    case 3:
      return SottoTipoContenuto.COOKIE_POLICY
    case 4:
      return SottoTipoContenuto.PRIVACY
    case 5:
      return SottoTipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI
    case 6:
      return SottoTipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI
    case 7:
      return SottoTipoContenuto.MANUALE_UTENTE
    case 8:
      return SottoTipoContenuto.PROCEDURA_WHISTLEBLOWING
    case 9:
      return SottoTipoContenuto.MISURE_DI_SICUREZZA
    case 10:
      return SottoTipoContenuto.LANDING_PAGE
    case 11:
      return SottoTipoContenuto.MANUALE_GESTORE
    default:
      return null
  }
}

export class GlobalVars {
  public static socket: any
  public static messaggiNonLetti: Messaggio[] = []
}


export function statoLavorazioneLeggibile(input: any) {
  let risultato: string
  switch (input.toString()) {
    case '1' || NUOVA:
      risultato = $localize`Nuova`
      break
    case '2' || ISTRUTTTORIA_APERTA:
      risultato = $localize`In Lavorazione`
      break
    case '3' || CHIUSA:
      risultato = $localize`Istruttoria Aperta`
      break
    case '4' || CHIUSA:
      risultato = $localize`Chiusa`
      break
    case '5' || INAMMISSIBILE:
      risultato = $localize`Non Ammissibile`
      break
    case '6' || NON_COMPETENTE:
      risultato = $localize`Non di Competenza`
      break
  }
  return risultato
}

export function statoSegnalazioneLeggibile(input: any, isSegnalante: boolean) {
  let risultato: string
  switch (input.toString()) {
    case '1' || BOZZA:
      risultato = $localize`Bozza`
      break
    case '2' || NESSUNA_LAVORAZIONE_IN_CORSO:
      risultato = $localize`Segnalazione ricevuta`
      break
    case '3' || LAVORAZIONI_IN_CORSO:
      risultato = $localize`Lavorazioni in corso`
      break
    case '4' || ALCUNE_LAVORAZIONI_CHIUSE:
      risultato = $localize`Alcune lavorazioni chiuse`
      break
    case '5' || TUTTE_LE_LAVORAZIONI_CHIUSE:
      risultato = $localize`Tutte le lavorazioni chiuse`
      break
    case '6' || RITIRATA:
      risultato = $localize`Ritirata`
      break
    case '7' || ISTRUTTORIA_IN_CORSO:
      risultato = $localize`Istruttoria in corso`
      break
    case '8' || SEGNALAZIONE_RICEVUTA:
      risultato = $localize`Segnalazione ricevuta`
      break
  }
  return risultato
}

export function decodificaStatoRichiestaConsenso(input: number) {
  switch (input) {
    case Consenso.STATI.ATTESA_INOLTRO:
      return $localize`Da inoltrare al segnalante`
    case Consenso.STATI.RICHIESTA_INOLTRATA:
      return $localize`Inoltrata al segnalante`
    case Consenso.STATI.CONCESSO:
      return $localize`Concesso`
    case Consenso.STATI.NEGATO:
      return $localize`Negato`
    case Consenso.STATI.RICHIESTA_ANNULLATA:
      return $localize`Richiesta Annullata`
    default:
      return $localize`Attesa Autorizzazione`
  }
}

export function disabilitaBack() {
  history.pushState(null, null, location.href)
  window.onpopstate = function () {
    history.go(1)
  }
}

export function salvaFile(documento: Documento) {
  // console.log('scarica non ancora implementato');
  // const bitmap = new Buffer(documento.base64, 'base64').toString();
  const b64 = documento.base64.split(',')[1]
  const tipo = documento.base64.split(',')[0]
  try {
    const blob = b64ToBlob(b64, tipo, 512)
    saveAs(blob, documento.nome, { type: tipo })
  } catch (error) {
    console.error(error)
  }
}

export function makeid() {
  let text = ''
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 5; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

function download(uno, due, tre) { }

export function apriChatConSegnalanteOGestore(
  lavorazione: Lavorazione,
  chatService: ChatService,
  authService: AuthService,
  router: Router,
  snackBar: MatSnackBar,
  sessionData: SessionData
) {
  if (!lavorazione.segnalazione) {
    console.error('lavorazione senza segnalazione')
    return
  }
  // prima prova a recuperare la chat con il segnalante legata alla lavorazione
  chatService
    .recuperaChatByLavorazione(+lavorazione.id)
    .then((esito) => {
      if (esito.esito === ESITO_OK) {
        const chat = JSON.parse(esito.payload) as Chat
        sessionData.chatSelezionata = chat
        SessionData.lavorazioneSelezionata = lavorazione
        router.navigate(['/chat-manager'], {
          queryParams: { chatSegnalanteGestore: true },
        })
      } else {
        //  prima recupera chat con gestore poi aprila
        chatService
          .recuperaChatBySegnalazione(+lavorazione.segnalazione.id)
          .then((esito2) => {
            if (esito2.esito === ESITO_OK) {
              const chats = JSON.parse(esito2.payload) as Chat[]
              sessionData.chatSelezionata = chats.find(
                (chat) => chat.chatConSegnalante
              )
              if (!sessionData.chatSelezionata) {
                sessionData.chatSelezionata = chats[0]
              }
              SessionData.lavorazioneSelezionata = lavorazione
              router.navigate(['/chat-manager'], {
                queryParams: { chatSegnalanteGestore: false },
              })
            } else {
              snackBar.open(
                $localize`Al momento non sei coinvolto in nessuna chat.`,
                null,
                {
                  duration: 4000,
                }
              )
            }
          })
          .catch((errore) => {
            snackBar.open(
              $localize`Impossibile recuperare la chat:` + errore,
              null,
              {
                duration: 4000,
              }
            )
          })
      }
    })
    .catch((errore) => {
      snackBar.open($localize`Impossibile recuperare la chat:` + errore, null, {
        duration: 4000,
      })
    })
}

export function apriChatLavorazione(
  lavorazione: Lavorazione,
  chatService: ChatService,
  authService: AuthService,
  router: Router,
  snackBar: MatSnackBar,
  sessionData: SessionData
) {
  if (!lavorazione.segnalazione) {
    console.error('lavorazione senza segnalazione')
    return
  }
  // prima prova a recuperare la chat con il segnalante legata alla lavorazione
  chatService
    .recuperaChatByLavorazione(+lavorazione.id)
    .then((esito) => {
      if (esito.esito === ESITO_OK) {
        const chat = JSON.parse(esito.payload) as Chat
        sessionData.chatSelezionata = chat
        SessionData.lavorazioneSelezionata = lavorazione
        router.navigate(['/chat-manager'], {
          queryParams: { chatSegnalanteGestore: true },
        })
      } else {
        snackBar.open(
          $localize`Al momento non sei coinvolto in nessuna chat.`,
          null,
          {
            duration: 4000,
          }
        )
      }
    })
    .catch((errore) => {
      snackBar.open($localize`Impossibile recuperare la chat:` + errore, null, {
        duration: 4000,
      })
    })
}

export function caricaChatLavorazione(
  lavorazione: Lavorazione,
  chatService: ChatService
) {
  // prima prova a recuperare la chat con il segnalante legata alla lavorazione
  chatService
    .recuperaChatByLavorazione(+lavorazione.id)
    .then((esito) => {
      if (esito.esito === ESITO_OK) {
        const chat = JSON.parse(esito.payload) as Chat
        lavorazione.chatConSegnalante = chat
      }
    })
    .catch((errore) => {
      console.error('Impossibile recuperare la chat:', errore)
    })
}

export function chatCompareFn(v1: Chat, v2: Chat): boolean {
  return v1 && v2 ? v1.id === v2.id : v1 === v2
}

export function assert(condizione: any, messaggioSuFalse: string) {
  if (!condizione) {
    console.error('ASSERT ERROR', messaggioSuFalse)
  }
}

export function isValidEmailAziendale(email: string): boolean {
  const dominio = email ? email.split('@')[1] : ''
  if (
    this.sessionData.configurazione &&
    this.sessionData.configurazione.dominiAziendali &&
    this.sessionData.configurazione.dominiAziendali
      .split(',')
      .filter((ema) => ema === dominio).length > 0
  ) {
    return true
  }
  return false
}

export function isValidEmailPersonale(email: string): boolean {
  return !isValidEmailAziendale(email)
}

export function calcolaStatoRichiestaSbloccoSegnalante(
  richiesta: DirittiSegnalazione
) {
  if (richiesta.richiestaSbloccoVisibilitaSegnalanteAccolta) {
    return $localize`Autorizzata`
  } else if (
    richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta &&
    richiesta.dataRichiestaSbloccoVisibilitaSegnalante <
    richiesta.dataRispostaARichiestaSbloccoVisibilitaSegnalante
  ) {
    return $localize`Respinta`
  } else if (
    richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta &&
    richiesta.dataRichiestaSbloccoVisibilitaSegnalante >
    richiesta.dataRispostaARichiestaSbloccoVisibilitaSegnalante
  ) {
    return $localize`Respinta - Nuova Richiesta`
  } else if (richiesta.dataRichiestaSbloccoVisibilitaSegnalante !== undefined) {
    return $localize`Da lavorare`
  } else {
    return $localize`Inattiva`
  }
}

export function randomString(
  lowercaseletters,
  uppercaseletters,
  numbers,
  symbols
) {
  const chars = [
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ', // Uppercase letters
    'abcdefghijklmnopqrstuvwxyz', // Lowercase letters
    '0123456789', // numbers
    // eslint-disable-next-line no-useless-escape
    '^!@#$%^*?~_£()-', // symbols
  ]

  return [lowercaseletters, uppercaseletters, numbers, symbols]
    .map(function (len, i) {
      return Array(len)
        .fill(chars[i])
        .map(function (x) {
          return x[Math.floor(Math.random() * x.length)]
        })
        .join('')
    })
    .concat()
    .join('')
    .split('')
    .sort(function () {
      return 0.5 - Math.random()
    })
    .join('')
}

export function openDialogGenerica(
  dialog,
  oggetto: string,
  messaggio: string,
  domanda: string,
  onOk: any,
  onKo: any,
  otherActionLabel?: string,
  onOtherAction?: any,
  width = '500px',
  closeAfterSeconds = 0,
  etichettaSelezione = '',
  elencoOggettiSelezione = [],
  input = false,
  hasBackdrop = true,
  annulla = false,
  continua = false
): void {
  let timeout = null
  console.log(
    `La dialog generica si chiuderà tra ${closeAfterSeconds} secondi...`
  )
  const dialogRef = dialog.open(ConfermaDecisioneDialogComponent, {
    width: width,
    data: {
      oggetto: oggetto,
      messaggio: messaggio,
      domanda: domanda,
      input: input,
      labelAltraAzione: otherActionLabel,
      altraAzione: onOtherAction,
      onlyOk: onKo === undefined,
      annulla: annulla,
      continua: continua,
    },
    disableClose: true,
    hasBackdrop: hasBackdrop,
  })

  if (closeAfterSeconds > 0 && dialogRef) {
    // dialogRef.afterOpened().subscribe(_ => {
    timeout = setTimeout(() => {
      if (onKo) {
        onKo()
      }
      dialogRef.close()
    }, closeAfterSeconds * 1000)
    // })
  }

  dialogRef.afterClosed().subscribe((result) => {
    // stop timeout
    clearTimeout(timeout)
    if (result) {
      onOk(result)
    } else {
      // verifica se onKo è definito
      if (onKo) {
        onKo()
      }
    }
  })
  return dialogRef
}

export function _arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export function createDocumentFromFile(file: File): Promise<VersioneContenuto> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = function (e) {
      try {
        const documento = new VersioneContenuto(
          _arrayBufferToBase64(reader.result),
          file.name,
          file.type
        )
        documento['file'] = file
        documento.size = file.size
        resolve(documento)
      } catch (error) {
        reject(error)
      }
    }

    reader.onerror = function (e) {
      reject(new Error('File reading failed'))
    }

    reader.readAsArrayBuffer(file)
  })
}

export function _base64ToArrayBuffer(base64) {

  // mi accerto che base64 non sia null o undefined
  if (!base64) {
    return null
  }
  // elimino eventuali header del tipo data:image/jpeg;base64, o nel caso di audio mp3 data:audio/mp3;base64,
  const base64String = base64.split(',')[1]
  const binaryString = window.atob(base64String)

  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

interface FAQ {
  id: string;
  domanda: string;
  risposta: string;
  stato: string;
}

export function estraiFaq(faqHtml: string): Faq[] {
  if (!faqHtml) {
    return []
  }
  const tempString = faqHtml.replace(/<\/p>/g, '')
  // console.log(tempString)
  const faqArray = tempString.split(/<p\s*[^>]*>/i);
  // console.log(faqArray)
  const arrayBuono = faqArray.filter((str) => str)
  // console.log(arrayBuono)
  const faqList = []
  let faq
  let id = 0
  arrayBuono.forEach((chunk) => {
    if (chunk.startsWith('<b>')) {
      if (faq) {
        faqList.push(faq)
      }
      faq = {
        id: 'domanda' + id,
        domanda: chunk,
        risposta: '',
        stato: 'normale',
      }
      id++
    } else {
      faq = { ...faq, risposta: faq?.risposta + chunk }
    }
  })
  if (faq) {
    faqList.push(faq)
  }

  // console.log(faqList)
  return faqList
}



export function decodeHtml(html) {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export function traduzioniAdapter(
  itemsToBeAdapted: any[],
  lingue: string[]
): any[] {
  const items = []
  itemsToBeAdapted.forEach((item) => {
    const newItem = {
      ...item,
      lingua: item['lingua'],
    }
    // verifico se item ha una proprieta chiamata azione
    // se si, allora la uso per recuperare il testo
    // se no, allora uso la proprietà defaultTesto
    if (item['azione']) {
      newItem.testo = item['azione']
    } else if (item['testo']) {
      newItem.testo = item['testo']
    } else if (item['tema']) {
      newItem.testo = item['tema']
    } else if (item['nome']) {
      newItem.testo = item['nome']
    } else {
      newItem.testo = item['defaultTesto']
    }
    items.push(newItem)
  })
  // verifico se ci sono lingue non presenti, lo faccio verificando che per ciascun hash ci sia un item per ogni lingua
  lingue.forEach((lingua) => {
    // ora riicavo l'elenco deglii hash univoci presenti in items
    const hashes = items.map((item) => item.hash)
    // verifico se per ogni hash c'è un item per la lingua corrente
    hashes.forEach((hash) => {
      const item = items.find(
        (item1) => item1.hash === hash && item1.lingua === lingua
      )
      if (!item) {
        // se non c'è, allora lo creo ma non copio l'id che setto nullo
        const itemToBeAdded = {
          ...items.find((item1) => item1.hash === hash),
          lingua: lingua,
        }
        itemToBeAdded.id = null
        items.push(itemToBeAdded)
      }
    })
  })

  return items
}

export function decycle(obj: any, stack: Set<any> = new Set()): any {
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  // Gestisci oggetti Date
  if (obj instanceof Date) {
    return new Date(obj.getTime())
  }

  if (stack.has(obj)) {
    return // Rimuovi il riferimento circolare
  }

  const newObj = Array.isArray(obj) ? [] : {}
  stack.add(obj)

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = decycle(obj[key], new Set(stack))
    }
  }

  return newObj
}

export function validaCodiceFiscale(codiceFiscale: string): boolean {
  let i: number
  let errori: { [key: string]: any }
  let cf = codiceFiscale
  if (cf === '' || cf === null || cf === undefined) {
    return null
  }
  cf = cf.toUpperCase()
  if (cf.length !== 16) {
    return false
  }
  const validi = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (i = 0; i < 16; i++) {
    if (validi.indexOf(cf.charAt(i)) === -1) {
      return false
    }
  }
  const set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const setpari = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const setdisp = 'BAKPLCQDREVOSFTGUHMINJWZYX'
  let s = 0
  for (i = 1; i <= 13; i += 2) {
    s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
  }
  for (i = 0; i <= 14; i += 2) {
    s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
  }
  if (s % 26 !== cf.charCodeAt(15) - 'A'.charCodeAt(0)) {
    return false
  }
  return true
}

async function removeImageMetadata(
  file: File,
  buffer: ArrayBuffer
): Promise<string> {
  return new Promise((resolve, reject) => {
    // Leggi il file come ArrayBuffer
    // const buffer = await file.arrayBuffer()

    if (file.type === 'image/jpeg') {
      // Estrai i metadati EXIF dall'ArrayBuffer
      const exifData = EXIF.readFromBinaryFile(buffer)

      // Se i metadati EXIF sono presenti, rimuovili
      if (exifData) {
        const blob = new Blob([buffer], { type: file.type })
        const imageUrl = URL.createObjectURL(blob)

        const img = new Image()
        img.src = imageUrl

        img.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          // Ottieni l'immagine come base64 senza metadati EXIF
          const base64 = canvas.toDataURL(file.type)
          resolve(base64)
        }

        img.onerror = function () {
          reject(
            new Error(
              'Si è verificato un errore durante la lettura del file immagine.'
            )
          )
        }
      } else {
        // Se non sono presenti metadati EXIF, restituisci direttamente l'immagine come base64
        const base64 = bufferToBase64(buffer, file.type)
        resolve(base64)
      }
    } else if (file.type === 'image/png') {
      const decoded = UPNG.decode(buffer)
      const rgbaData = UPNG.toRGBA8(decoded)[0]

      // Rimuovi i metadati tEXt, zTXt e iTXt
      decoded.text = {}

      // Ricrea il PNG senza metadati
      const encoded = UPNG.encode(
        [rgbaData],
        decoded.width,
        decoded.height,
        decoded.depth,
        decoded.tabs
      )

      // Converte il PNG senza metadati in base64
      const base64 = bufferToBase64(encoded, file.type)
      resolve(base64)
    } else {
      reject(
        new Error(
          'Tipo di file non supportato. Sono supportati solo file JPEG e PNG.'
        )
      )
    }
  })
}

async function removePdfMetadata(inputBuffer) {
  // Carica il documento PDF con pdf-lib
  const originalDoc = await PDFDocument.load(inputBuffer)

  // Crea un nuovo documento PDF con pdf-lib
  const pdfLibDocument = await PDFDocument.create()

  // Copia tutte le pagine dal documento originale al nuovo documento
  const pageIndices = Array.from(
    { length: originalDoc.getPageCount() },
    (_, i) => i
  )
  const copiedPages = await pdfLibDocument.copyPages(originalDoc, pageIndices)

  for (let i = 0; i < copiedPages.length; i++) {
    const copiedPage = copiedPages[i]
    pdfLibDocument.addPage(copiedPage)
  }

  // Rimuovi i metadati
  pdfLibDocument.setTitle('')
  pdfLibDocument.setAuthor('')
  pdfLibDocument.setSubject('')
  pdfLibDocument.setKeywords([])
  pdfLibDocument.setProducer('')
  pdfLibDocument.setCreator('')

  // Serializza il nuovo documento PDF senza metadati
  const outputBuffer = await pdfLibDocument.save()

  return outputBuffer
}

export async function processFile(file: File) {
  let base64 = null

  if (file.type.startsWith('image/')) {
    // Rimuovi i metadati dell'immagine e convertila in base64
    base64 = await removeImageMetadata(file, await file.arrayBuffer())

    // Converte l'immagine in PDF
    base64 = await convertiInPdf(base64, file.type)
  } else if (file.type === 'application/pdf') {
    // Leggi il contenuto del file come ArrayBuffer
    const buffer = await file.arrayBuffer()

    // Rimuovi i metadati del PDF
    const outputBuffer = await removePdfMetadata(buffer)

    // Converte il risultato in base64
    const uint8Array = new Uint8Array(outputBuffer)
    const chunkSize = 8192 // scegli una dimensione appropriata per il chunk
    let binary = ''
    for (let i = 0; i < uint8Array.length; i += chunkSize) {
      const chunk = uint8Array.slice(i, i + chunkSize)
      binary += String.fromCharCode.apply(null, chunk)
    }
    base64 = window.btoa(binary)
  } else if (file.type.startsWith('audio/') || file.type.startsWith('video/')) {
    // converte il contenuto del file in base64
    base64 = await file.arrayBuffer().then((buffer) => {
      let binary = ''
      const bytes = new Uint8Array(buffer)
      const len = bytes.byteLength
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      return window.btoa(binary)
    })
  }

  return base64
}

export function getOptimalTextColor(backgroundColor) {
  let color
  if (backgroundColor === null || backgroundColor === undefined) {
    return 'black'
  }

  if (backgroundColor.startsWith('#')) {
    const rgb = hexToRgb(backgroundColor)
    color = [rgb.r, rgb.g, rgb.b]
  } else if (backgroundColor.startsWith('rgb')) {
    color = backgroundColor
      .slice(4, -1)
      .split(',')
      .map((c) => Number(c.trim()))
  }

  // Calcola la luminanza
  const luminance = 0.299 * color[0] + 0.587 * color[1] + 0.114 * color[2]

  // Restituisci il colore del testo in base alla luminanza
  return luminance > 160 ? 'black' : 'ghostwhite'
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null
}

function luminance(r, g, b) {
  const a = [r, g, b].map((v) => {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export function dominantComponent(color) {
  if (color.r > color.g && color.r > color.b) {
    return 'r'
  } else if (color.g > color.r && color.g > color.b) {
    return 'g'
  } else {
    return 'b'
  }
}

function optimizeComponent(component1, component2) {
  const direction = component1 > 128 ? -1 : 1
  const optimizedComponent = component2 + 64 * direction

  // Ensure the component is within [0, 255]
  return Math.max(0, Math.min(255, optimizedComponent))
}

function rgbToHex(r, g, b) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

export function increaseContrastToThreshold(baseColor, targetContrast) {
  const baseRgb = hexToRgb(baseColor)

  // somma le componenti rgb
  const sum = baseRgb.r + baseRgb.g + baseRgb.b
  // se è maggiore di 400 allora la direzione è verso il nero
  const direzione = sum > 400 ? -1 : 1

  const currentColor = { ...baseRgb }
  const maxIterations = 1000

  for (let i = 0; i < maxIterations; i++) {
    currentColor.r = Math.max(0, Math.min(255, currentColor.r + direzione))
    currentColor.g = Math.max(0, Math.min(255, currentColor.g + direzione))
    currentColor.b = Math.max(0, Math.min(255, currentColor.b + direzione))

    const contrastRatio = calculateContrast(baseRgb, currentColor)

    if (contrastRatio >= targetContrast) {
      return rgbToHex(currentColor.r, currentColor.g, currentColor.b)
    }
  }

  return rgbToHex(currentColor.r, currentColor.g, currentColor.b)
}

function calculateContrast(color1, color2) {
  const l1 = calculateLuminance(color1) + 0.05
  const l2 = calculateLuminance(color2) + 0.05
  return Math.max(l1, l2) / Math.min(l1, l2)
}

function calculateLuminance(color) {
  const srgb = ['r', 'g', 'b'].map((channel) => color[channel] / 255)
  const linear = srgb.map((value) =>
    value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4)
  )
  return 0.2126 * linear[0] + 0.7152 * linear[1] + 0.0722 * linear[2]
}

export function hexToFilterColorBackup(hexColor: string): string {
  // Convert the hex color to RGB
  const hex = hexColor.replace('#', '')
  const r = parseInt(hex.slice(0, 2), 16) / 255
  const g = parseInt(hex.slice(2, 4), 16) / 255
  const b = parseInt(hex.slice(4, 6), 16) / 255

  // Calculate the filter values (this is just an approximation)
  const invert = Math.abs((r + g + b) / 3 - 1) * 100
  const sepia = (1 - (r + g + b) / 3) * 100
  const saturate = (r + g + b) * 1200
  const hueRotate = (Math.acos((r - g) / (r + g)) / Math.PI) * 180
  const brightness = 100
  const contrast = ((2 * (r + g + b)) / 3) * 100

  // Construct the filter string
  const filters = `invert(${invert.toFixed(0)}%) sepia(${sepia.toFixed(
    0
  )}%) saturate(${saturate.toFixed(0)}%) hue-rotate(${hueRotate.toFixed(
    0
  )}deg) brightness(${brightness.toFixed(0)}%) contrast(${contrast.toFixed(
    0
  )}%)`

  return filters
}

export class Color {
  r: number
  g: number
  b: number

  constructor(r, g, b) {
    this.set(r, g, b)
  }

  toString() {
    return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(
      this.b
    )})`
  }

  set(r, g, b) {
    this.r = this.clamp(r)
    this.g = this.clamp(g)
    this.b = this.clamp(b)
  }

  hueRotate(angle = 0) {
    angle = (angle / 180) * Math.PI
    const sin = Math.sin(angle)
    const cos = Math.cos(angle)

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.14,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ])
  }

  grayscale(value = 1) {
    this.multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value),
    ])
  }

  sepia(value = 1) {
    this.multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value),
    ])
  }

  saturate(value = 1) {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value,
    ])
  }

  multiply(matrix) {
    const newR = this.clamp(
      this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2]
    )
    const newG = this.clamp(
      this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5]
    )
    const newB = this.clamp(
      this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8]
    )
    this.r = newR
    this.g = newG
    this.b = newB
  }

  brightness(value = 1) {
    this.linear(value)
  }
  contrast(value = 1) {
    this.linear(value, -(0.5 * value) + 0.5)
  }

  linear(slope = 1, intercept = 0) {
    this.r = this.clamp(this.r * slope + intercept * 255)
    this.g = this.clamp(this.g * slope + intercept * 255)
    this.b = this.clamp(this.b * slope + intercept * 255)
  }

  invert(value = 1) {
    this.r = this.clamp((value + (this.r / 255) * (1 - 2 * value)) * 255)
    this.g = this.clamp((value + (this.g / 255) * (1 - 2 * value)) * 255)
    this.b = this.clamp((value + (this.b / 255) * (1 - 2 * value)) * 255)
  }

  hsl() {
    // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
    const r = this.r / 255
    const g = this.g / 255
    const b = this.b / 255
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h,
      s,
      l = (max + min) / 2

    if (max === min) {
      h = s = 0
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break

        case g:
          h = (b - r) / d + 2
          break

        case b:
          h = (r - g) / d + 4
          break
      }
      h /= 6
    }

    return {
      h: h * 100,
      s: s * 100,
      l: l * 100,
    }
  }

  clamp(value) {
    if (value > 255) {
      value = 255
    } else if (value < 0) {
      value = 0
    }
    return value
  }
}

export class Solver {
  target: any
  targetHSL: any
  reusedColor: Color
  constructor(target) {
    this.target = target
    this.targetHSL = target.hsl()
    this.reusedColor = new Color(0, 0, 0)
  }

  solve() {
    const result = this.solveNarrow(this.solveWide())
    return {
      values: result.values,
      loss: result.loss,
      filter: this.css(result.values),
    }
  }

  solveWide() {
    const A = 5
    const c = 15
    const a = [60, 180, 18000, 600, 1.2, 1.2]

    let best = { loss: Infinity }
    for (let i = 0; best.loss > 25 && i < 3; i++) {
      const initial = [50, 20, 3750, 50, 100, 100]
      const result = this.spsa(A, a, c, initial, 1000)
      if (result.loss < best.loss) {
        best = result
      }
    }
    return best
  }

  solveNarrow(wide) {
    const A = wide.loss
    const c = 2
    const A1 = A + 1
    const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1]
    return this.spsa(A, a, c, wide.values, 500)
  }

  spsa(A, a, c, values, iters) {
    const alpha = 1
    const gamma = 0.16666666666666666

    let best = null
    let bestLoss = Infinity
    const deltas = new Array(6)
    const highArgs = new Array(6)
    const lowArgs = new Array(6)

    for (let k = 0; k < iters; k++) {
      const ck = c / Math.pow(k + 1, gamma)
      for (let i = 0; i < 6; i++) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1
        highArgs[i] = values[i] + ck * deltas[i]
        lowArgs[i] = values[i] - ck * deltas[i]
      }

      const lossDiff = this.loss(highArgs) - this.loss(lowArgs)
      for (let i = 0; i < 6; i++) {
        const g = (lossDiff / (2 * ck)) * deltas[i]
        const ak = a[i] / Math.pow(A + k + 1, alpha)
        values[i] = fix(values[i] - ak * g, i)
      }

      const loss = this.loss(values)
      if (loss < bestLoss) {
        best = values.slice(0)
        bestLoss = loss
      }
    }
    return { values: best, loss: bestLoss }

    function fix(value, idx) {
      let max = 100
      if (idx === 2 /* saturate */) {
        max = 7500
      } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
        max = 200
      }

      if (idx === 3 /* hue-rotate */) {
        if (value > max) {
          value %= max
        } else if (value < 0) {
          value = max + (value % max)
        }
      } else if (value < 0) {
        value = 0
      } else if (value > max) {
        value = max
      }
      return value
    }
  }

  loss(filters) {
    // Argument is array of percentages.
    const color = this.reusedColor
    color.set(0, 0, 0)

    color.invert(filters[0] / 100)
    color.sepia(filters[1] / 100)
    color.saturate(filters[2] / 100)
    color.hueRotate(filters[3] * 3.6)
    color.brightness(filters[4] / 100)
    color.contrast(filters[5] / 100)

    const colorHSL = color.hsl()
    return (
      Math.abs(+color.r - this.target.r) +
      Math.abs(+color.g - this.target.g) +
      Math.abs(+color.b - this.target.b) +
      Math.abs(colorHSL.h - this.targetHSL.h) +
      Math.abs(colorHSL.s - this.targetHSL.s) +
      Math.abs(colorHSL.l - this.targetHSL.l)
    )
  }

  css(filters) {
    function fmt(idx, multiplier = 1) {
      return Math.round(filters[idx] * multiplier)
    }
    return `filter: invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(
      2
    )}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(
      5
    )}%);`
  }
}

export function hexToFilterColor(hexColor: string): string {
  let loss = 10
  let result = null
  while (loss > 1) {
    const colore = new Color(
      hexToRgb(hexColor).r,
      hexToRgb(hexColor).g,
      hexToRgb(hexColor).b
    )
    const solver = new Solver(colore)
    result = solver.solve()

    loss = result.loss
  }
  console.log(result)
  return result?.filter
}

export function lightenColor(hexColor: string): string {
  // Rimuovi il simbolo hashtag se presente
  if (hexColor.startsWith('#')) {
    hexColor = hexColor.substr(1)
  }

  // Assicurati che il colore esadecimale sia valido
  if (hexColor.length !== 6 || !/^[0-9a-fA-F]+$/.test(hexColor)) {
    throw new Error('Invalid hex color format')
  }

  // Converti il colore esadecimale in componenti RGB
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)

  // Aumenta ogni componente del 50%, assicurandoti che non superi 255
  const newR = Math.min(Math.round(r + (255 - r) * 0.5), 255)
  const newG = Math.min(Math.round(g + (255 - g) * 0.5), 255)
  const newB = Math.min(Math.round(b + (255 - b) * 0.5), 255)

  // Converti le nuove componenti RGB in un colore esadecimale
  const newHexColor = ((newR << 16) + (newG << 8) + newB)
    .toString(16)
    .padStart(6, '0')

  return `#${newHexColor}`
}

export function validateLandingVideoFileName(fileName: string): boolean {
  // RegExp che verifica il formato del file.
  // `secure-blowing-presentazione` seguito da un `-`, poi 2 caratteri alfabetici, un altro `-`, poi uno o più caratteri alfanumerici,
  // e infine `.mp4`.
  const regExp = /^secure-blowing-presentazione-\w{2}-\w+\.mp4$/

  return regExp.test(fileName)
}

export function apriSegnalazioneSelezionata(segnalazione, segnalazioniService, sessionData, router, snackBar) {
  SessionData.segnalazioneSelezionataElenco = segnalazione
  segnalazioniService.recuperaSegnalazione(+segnalazione.id).subscribe(
    (esito) => {
      if (esito.esito === ESITO_OK) {
        sessionData.nuovaSegnalazioneSelezionata(
          JSON.parse(esito.payload)
        )
        // controlla se mi trovo già nella pagina di segnalazione
        if (router.url.indexOf('segnalazione') === -1) {
          router.navigate(['/segnalazione'])
        } else {
          // se sono già nella pagina di segnalazione, ricarica la pagina
          router.navigate(['/segnalazione'], { queryParams: { reload: 'true' } })
        }
      } else {
        snackBar.open(
          $localize`Non è stato possibile recuperare la segnalazione ` +
          JSON.stringify(esito.descrizioneEsito),
          null,
          {
            duration: 4000,
          }
        )
      }
    },
    () => {
      snackBar.open(
        $localize`Non è stato possibile recuperare la segnalazione ` +
        segnalazione.idPerSegnalante,
        null,
        {
          duration: 4000,
        }
      )
    }
  )
}
