import { Injectable, Injector } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Subject, Subscription } from 'rxjs'
import { SessionData } from '../sessione/dati-sessione'
import { openDialogGenerica } from '../utility/utility'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root',
})
export class HeartbeatService {
  private lastHeartbeatTime = 0
  private sub: Subscription
  timeoutAvvisoScadenzaSessione: NodeJS.Timeout
  timeoutScadenzaSessione: NodeJS.Timeout
  sessioneInScadenza = new Subject()
  dialogRef: any

  dialogIsOpen = false;
  constructor(
    private dialog: MatDialog,
    private sessionData: SessionData,
    private route: Router,
    private injector: Injector
  ) {
    this.startHeartbeat()
  }
  private _authService: AuthService | null = null;

  private get authService(): AuthService {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }
    return this._authService;
  }

  cleanup() {
    // Questo metodo agisce come ngOnDestroy per un servizio
    document.removeEventListener('mousemove', this.onUserActivity.bind(this));
    document.removeEventListener('keydown', this.onUserActivity.bind(this));
    clearTimeout(this.timeoutAvvisoScadenzaSessione);
    clearTimeout(this.timeoutScadenzaSessione);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.sub?.unsubscribe()
  }

  private startHeartbeat() {
    document.addEventListener('mousemove', this.onUserActivity.bind(this))
    document.addEventListener('keydown', this.onUserActivity.bind(this))
    this.lastHeartbeatTime = Date.now()
    this.avviaTimer()

    this.sub = this.sessioneInScadenza.subscribe(() => {
      console.log(
        `********************** sessione in scadenza nei prossimi ${this.sessionData.configurazione.sogliaAvvisoSecondiScadenzaSessione} secondi !!! *********************`
      )
      this.dialogRef = openDialogGenerica(
        this.dialog,
        $localize`Sessione in scadenza`,
        $localize`La tua sessione scadrà nei prossimi ${this.sessionData.configurazione.sogliaAvvisoSecondiScadenzaSessione} secondi. Per non perdere i dati ancora non salvati è necessario rinnovarla`,
        $localize`Clicca il bottone sotto per aggiornare la sessione`,
        () => this.authService.rinnovaToken().then(() => {
          this.lastHeartbeatTime = Date.now(); // Aggiorna il tempo dell'ultimo heartbeat
          this.avviaTimer(); // Riavvia i timer
          this.dialogIsOpen = false; // Assicurati di chiudere il dialog
        }),
        undefined,
        undefined,
        undefined,
        '50vw',
        this.sessionData.configurazione.sogliaAvvisoSecondiScadenzaSessione
      )
      this.dialogIsOpen = true;
      // mi assicuro che alla chiusura della finestra di dialogo venga resettato il booleano dialogIsOpen
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogIsOpen = false;
      })
    })
  }

  private onUserActivity(event: MouseEvent | KeyboardEvent) {
    // this.sub.unsubscribe()

    // se l'utente non e' loggato non faccio niente
    if (this.authService.isLoggedIn() === false) {
      return
    }

    // chiudi la finestra di dialogo se è aperta
    // se la finestra è aperta non resetto il timer
    if (this.dialogIsOpen) {
      return
    }

    const currentTime = Date.now()
    const timeSinceLastHeartbeat =
      (currentTime - this.lastHeartbeatTime) / 1000
    if (timeSinceLastHeartbeat > 15) {
      this.authService.isLoggedIn()
      this.lastHeartbeatTime = currentTime
      this.avviaTimer()
    }
  }

  avviaTimer() {
    // annullo il timeout di scadenza della sessione
    clearTimeout(this.timeoutAvvisoScadenzaSessione)
    clearTimeout(this.timeoutScadenzaSessione)
    if (!this.authService.isLoggedIn()) {
      // ricavo l'ultima parte dell'href del document corrente
      const pagina = window.location.href.split('/').pop()

      const paginaNonIniziaPerRigeneraMfa = !pagina?.startsWith('rigenera-mfa')
      const paginaNonInziaPerVerificaEmail = !pagina?.startsWith('verifica-email')
      const paginaNonInziaPerRecuperoPassword = !pagina?.startsWith('recupero-password')
      const paginaNonIniziaPerLoginConRicPin = !pagina?.startsWith('login?ricpin')


      // Aggiungi un controllo per evitare il reindirizzamento se siamo su /rigenera-mfa
      if (pagina !== 'login' && paginaNonIniziaPerRigeneraMfa &&
        paginaNonInziaPerVerificaEmail &&
        paginaNonInziaPerRecuperoPassword &&
        paginaNonIniziaPerLoginConRicPin) {
        if (this.authService.getUser()?.odv) {
          this.route.navigate(['/login'])
        } else {
          this.route.navigate(['/landing-page'])
        }
      }
      return
    }
    if (this.sessionData?.configurazione === undefined) {
      return
    }
    // ne predispongo uno nuovo
    const durataSessione = this.sessionData?.configurazione?.durataSessione
    const sogliaAvvisoSecondiScadenzaSessione =
      this.sessionData?.configurazione?.sogliaAvvisoSecondiScadenzaSessione
    const tempoScadenzaSessione =
      durataSessione - sogliaAvvisoSecondiScadenzaSessione
    this.timeoutAvvisoScadenzaSessione = setTimeout(() => {
      // se siamo loggati mostro il dialogo di avviso
      if (this.authService.isLoggedIn()) {
        this.sessioneInScadenza.next()
      }

    }, tempoScadenzaSessione * 1000)
    this.timeoutScadenzaSessione = setTimeout(() => {
      if (this.route.url !== '/login') {
        if (this.authService.getUser()?.odv) {
          this.route.navigate(['/login'])
        } else {
          this.route.navigate(['/landing-page'])
        }
        this.authService.logout()
      }
    }, durataSessione * 1000)
  }
}
