import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servizi/auth.service';
import { SessionData } from '../../sessione/dati-sessione';
import { Esito, ESITO_OK } from '../../utility/esito';

@Component({
  selector: 'app-attivazione-mfa',
  templateUrl: './attivazione-mfa.component.html',
  styleUrls: ['./attivazione-mfa.component.scss']
})
export class AttivazioneMfaComponent {
  tokenRigeneraMfa: string;
  emailVerificata: boolean = false;
  imageSrc: any;
  errore: string;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar,
    private sessionData: SessionData,) {
      console.log('AttivazioneMfaComponent creato');
    }

  ngOnInit(): void {
    console.log('ngOnInit chiamato');
    this.route.queryParams.subscribe(params => {
      console.log('queryParams:', params);
      this.tokenRigeneraMfa = params['rigeneraMfa'];
      if (this.tokenRigeneraMfa) {
        this.getMfaQrCode();
      }
    });

    this.route.data.subscribe(data => {
      console.log('route data:', data);
      const configurazione = data['configurazione'];
      if (configurazione) {
        this.sessionData.configurazione = configurazione;
      } else {
        console.error('Errore nella configurazione:', data);
        this.router.navigate(['/landing-page'], { queryParams: { initialize: true } });
      }
    });
  }

  getMfaQrCode() {
    this.authService.getMfaQrCode(this.tokenRigeneraMfa).
      subscribe(esito => {
        const codice = esito.esito
        switch (codice) {
          case ESITO_OK:
            const payload = JSON.parse(esito.payload)
            if (payload['qrCodeImage']) {
              console.log('Attivato MFA')
              // ho ricevuto l'immagine di un qr code in base64 la visualizzo nella pagina
              this.imageSrc = payload['qrCodeImage']
            }
            break
          case Esito.TOKEN_NON_VALIDO:
            this.errore = $localize`Il token non è valido.`
            break
          case Esito.TOKEN_SCADUTO:
            this.errore = $localize`Il token è scaduto.`
            break
          default:
            this.errore = $localize`Errore nella rigenerazione del doppio fattore. `
            break
        }

      },
        err => {
          this.errore = $localize`Errore nella rigenerazione del doppio fattore. ` + err.toString()
        })
  }

  getLogoCliente() {
    return this.sessionData.logoCliente?.base64 ?? this.getLogoSecure();
  }

  getLogoSecure() {
    return this.sessionData.logoSecure?.base64 ?? '/assets/img/logo-secure.png';
  }


  backToMainLogin() {
    this.router.navigate(['/landing-page'], { queryParams: { initialize: true } })
  }
  apriPaginaLogin() {
    this.router.navigate(['/login'])
  }
}
