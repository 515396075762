import { Component, OnInit, OnDestroy } from '@angular/core'
import {
  GlobalVars,
} from './utility/utility'
import io from 'socket.io-client'
import { RoutingState } from './routing-helper/routing-state'
import { AuthService } from './servizi/auth.service'
import { SessionData } from './sessione/dati-sessione'
import { Directionality } from '@angular/cdk/bidi'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SessionDataService } from './servizi/session-data.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Secure Blowing'
  direction = 'ltr'
  constructor(
    routingState: RoutingState,
    private authService: AuthService,
    private sessionData: SessionData,
    dir: Directionality,
    private snackBar: MatSnackBar,
    private sessionDataService: SessionDataService
  ) {
    routingState.loadRouting()
    dir.change.subscribe(() => {
      this.direction = dir.value
    })
  }

  ngOnInit(): void {
    this.sessionDataService.initialize()
    const domain = window.location.hostname // Estrai il dominio dal window.location
    GlobalVars.socket = io(window.location.origin + '/' + domain, {
      forceNew: true,
      withCredentials: true,
      query: {
        domain: domain,
      },
      extraHeaders: {
        'my-custom-header': 'abcd',
      },
    })

    GlobalVars.socket.on('connect', function () {
      console.log('Socket connesso al namespace: ' + domain)
    })

    GlobalVars.socket.on('disconnect', function () {
      console.log('Socket disconnesso')
    })
  }


  public ngOnDestroy(): void {
    GlobalVars.socket.disconnect()
    this.authService.removeStoredCredentials()
    this.sessionData.reset()
  }
}
