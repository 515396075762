<div style="min-width: 300px;">
  <h2 i18n>Elenco allegati messaggio</h2>
  <table>
    <tr *ngFor="let allegato of allegati">
      <td color="accent">
        {{allegato.nome}}
      </td>
      <td>
        <button mat-icon-button (click)="eliminaAllegato(allegato)">
          <mat-icon color="accent" aria-label="cancella allegato">delete</mat-icon>
        </button>
      </td>
      <td>
        <button mat-icon-button (click)="visualizza(allegato)">
          <mat-icon color="accent" aria-label="visualizza allegato">remove_red_eye</mat-icon>
        </button>
      </td>
    </tr>
  </table>
  <!-- Bottone per la chiusura della dialog -->
  <div class="flex-row" style="margin-top:24px">
    <button mat-raised-button color="accent" i18n (click)="closeDialog()">Chiudi</button>
    <button mat-raised-button color="accent" i18n (click)="newAttachedFile.open()">Aggiungi allegato</button>
  </div>
  <form [formGroup]="formAllegati">
    <app-input-file #newattachedfile formControlName="files" i18n-placeholder placeholder="" style="width:50px;"
      valuePlaceholder="" multiple (change)="handleFileInput($event.target)"></app-input-file>
  </form>
</div>
