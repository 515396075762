
<div *ngIf="messaggi && chat" class="flex-column content box" #scrollMe scrollTracker
  (scrolled)="updateScrollPos($event)"
  style="height: {{chatHeight}}; overflow:scroll; overflow-x:hidden; background-color:whitesmoke; margin-top: -40px;">
  <div class="overlay flex centered" *ngIf="invioInCorso || loadInCorso">
    <mat-spinner diameter="50" strokeWidth="5" style="margin:auto"></mat-spinner>
  </div>
  <div class="flex-100 flex-column" style="justify-content: flex-end;">
    <div #messages *ngFor="let messaggio of messaggi | sort : 'dataInvio'">
      <div class="flex-column">
        <div *ngIf="mio(messaggio)" class="inviato-da destra flex-100"
          style="padding-top:20px; color: rgba(0, 0, 0, 0.7);text-align: end;margin-right: 16px;" i18n>
          inviato da {{ calcolaMittente(messaggio) }} il {{messaggio.dataInvio | date: 'dd/MM/yyyy'}} alle
          {{messaggio.dataInvio | date: 'H:mm'}}
        </div>
        <div *ngIf="!mio(messaggio)" class="inviato-da sinistra flex-100"
          style="padding-top:20px; color: rgba(0, 0, 0, 0.7); margin-left: 16px;" i18n>
          inviato da {{calcolaMittente(messaggio)}} il {{messaggio.dataInvio | date: 'dd/MM/yyyy'}} alle
          {{messaggio.dataInvio | date: 'H:mm'}}
        </div>
      </div>
      <div class="flex-100">
        <div *ngIf="mio(messaggio)" class="talk-bubble destra messaggio-inviato round tri-right right-in flex-100">
          <div class="flex-100">
            <div class="flex-row flex-space-between-center">
              <div></div>
              <button mat-icon-button (click)="onDeleteMessage(messaggio.id)">
                <mat-icon aria-label="elimina messaggio">cancel</mat-icon>
              </button>
            </div>
            <div class="flex-row flex-space-between-center">
              <div>{{messaggio.testo}}</div>
            </div>
            <div class="flex-row flex-space-between-center">
              <div>
                <button *ngFor="let allegato of messaggio.allegati" mat-icon-button matTooltipPosition="after"
                  (click)="visualizzaAllegato(allegato); $event.stopPropagation();" i18n-matTooltip
                  matTooltip="{{allegato.nome}}" style="z-index: 100;" class="margin-minus-6">
                  <mat-icon aria-label="visualizza allegato"
                    class="icone-barra-principale grande">{{iconaAllegato(allegato)}}</mat-icon>
                </button>
              </div>
            </div>
            <div class="flex-row flex-space-between-center">
              <!-- Aggiungi questo codice all'interno della riga <tr> con il *ngFor e dopo il tag <button> per gli allegati -->
              <div>
                <button *ngIf="messaggio.audioHash" mat-icon-button matTooltipPosition="after"
                  (click)="playAudio(messaggio); $event.stopPropagation();" i18n-matTooltip matTooltip="Ascolta audio"
                  class="margin-minus-6">
                  <mat-icon aria-label="Ascolta audio" class="icone-barra-principale">volume_up</mat-icon>
                </button>
              </div>
            </div>
            <div class="flex-row flex-space-between-center">
              <div class="flex-row-end">
                <div *ngFor="let stato of stati(messaggio)" class="flex-column">
                  <div style="text-align: center;">
                    <mat-icon *ngIf="!stato.read" class="messaggio-inviato" aria-label="stato messaggio"
                      >done</mat-icon>


                    <mat-icon *ngIf="stato.read" class="messaggio-letto" aria-label="stato messaggio"
                       >done_all
                    </mat-icon>
                  </div>
                  <div #chi class="small-font" style="text-align: center; ">
                    {{userId(stato)}}
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
        <div *ngIf="!mio(messaggio)" class="talk-bubble sinistra messaggio-ricevuto round tri-right left-in flex-100">
          <div class="flex-100">
            <div class="flex-row flex-space-between-center">
              <div class="talktext">
                <p i18n>
                  {{messaggio.testo}}
                </p>


              </div>
            </div>
            <div class="flex-row flex-space-between-center">
              <div>
                <button *ngFor="let allegato of messaggio.allegati" mat-icon-button matTooltipPosition="after"
                  (click)="visualizzaAllegato(allegato); $event.stopPropagation();" i18n-matTooltip
                  matTooltip="{{allegato.nome}}" style="z-index: 100;" class="margin-minus-6">
                  <mat-icon aria-label="visualizza allegato"
                    class="icone-barra-principale grande">{{iconaAllegato(allegato)}}</mat-icon>
                </button>
              </div>
            </div>
            <tr>
              <!-- Aggiungi questo codice all'interno della riga <tr> con il *ngFor e dopo il tag <button> per gli allegati -->
              <td>
                <button *ngIf="messaggio.audioHash" mat-icon-button matTooltipPosition="after"
                  (click)="playAudio(messaggio); $event.stopPropagation();" i18n-matTooltip matTooltip="Ascolta audio"
                  class="margin-minus-6">
                  <mat-icon aria-label="Ascolta audio" class="icone-barra-principale">volume_up</mat-icon>
                </button>
              </td>
            </tr>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-row footer" *ngIf="canSendMessages()">
    <div class="flex-row">
      <div *ngIf="formAllegati"  class="flex-row">
        <form [formGroup]="formAllegati" (ngSubmit)="onSubmit()" novalidate style="height:0px">
          <div style="width:42px!important;cursor: pointer; height:5px" class="allega">
            <button mat-icon-button class="icona-allegato" color="accent" matPrefix matBadge="{{fileCount}}" matBadgeColor="accent"
              [matBadgeHidden]="fileCount === 0" (click)="handleIconClick()">
              <mat-icon color="accent" aria-label="invia messaggio">attach_file</mat-icon></button>
            <app-input-file #attachedfiles formControlName="files" i18n-placeholder placeholder=""
              valuePlaceholder="" multiple (change)="handleFileInput($event.target)"
              (filesChanged)="updateBadgeCount($event);"
              (fileSelected)="handleFileInput($event.target)"
              (attachmentRemoved)="updateBadgeCount($event)"></app-input-file>
            <mat-error i18n *ngIf="formAllegati?.get('files').hasError('required')">
              Seleziona un allegato
            </mat-error>
            <mat-error i18n *ngIf="formAllegati?.get('files').hasError('maxContentSize')">
              I file non possono superare la dimensione di
              {{formAllegati?.get('requiredfile')?.getError('maxContentSize').maxSize | byteFormat}}
              ({{formAllegati?.get('requiredfile')?.getError('maxContentSize').actualSize | byteFormat}}).
            </mat-error>
          </div>
        </form>
      </div>
      <div>
        <app-recorder [isDisabled]="recorderDisabled" (processedBlobChanged)="onProcessedBlobChanged($event)"
          [isAnon]="isAnonima" [isAudioCifrato]="isAudioCifrato"
          (gestisciConsensoAudio)="onGestisciConsensoAudio($event)"></app-recorder>
      </div>

      <div class="flex-fill flex-100">
        <input class="flex-fill " style="width: 100% !important;" matInput [(ngModel)]="testoMessaggio"
          class="input-messaggio" i18n-placeholder placeholder="Digita messaggio..." maxlength="255"
          (keydown)="salvaSuInvio($event)" />
      </div>

      <button mat-icon-button (click)="inviaMessaggio(); $event.stopPropagation();" style="position:relative; margin-right: 5px;
  margin-top: -4px;">
        <mat-icon style="margin-left: 5px;" color="accent" aria-label="invia messaggio">send</mat-icon>
      </button>
    </div>
  </div>
</div>
